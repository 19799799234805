@import "../../variables";
@import "../../mixins";


.double-line {
  padding: 0 32px;

  &:not(:last-child) {
    border-right: 1px solid #c8c8c8;
  }

  .double-line-value {
    @include nowrap();
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: $tooltip-text-color;
    margin-bottom: 3px;
  }

  .double-line-title {
    @include nowrap();
    font-size: 14px;
    line-height: 1.57;
    color: $tooltip-text-color;
  }
}
