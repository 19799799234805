@import "./../../variables";
@import "./../../mixins";
@import "./../widgets/card";

#root .content table.standard thead td button.filter-button {
  float: none;
  top: 1px;
  left: 10px;
}

.dashboard-notification.notification-item {
  position: fixed;
  bottom: 80px;
  width: 840px;
  left: calc(50% - 420px);
  z-index: 1000;
  @media screen and ( max-width: 1500px ){
    bottom: 20px;
  }
}


.sync-dashboard {
  max-width: $dashboard-width;
  h1 {
    font-size: 24px;
    font-weight: normal;
  }

  .dashboard-action-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 30px;
    
    .create-button {
      width: 230px;
    }

    .dashboard-menu {
      line-height: 100%;
      margin-left: 20px;
    }
  }

  &.table {
    max-width: 1539px;
    @media screen and ( max-width: 1500px ){
      max-width: 1300px;
    }
  }
  margin: 0 auto;
  //for the popover icon on sync-info both for the table and for the relationship card
  .flex {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
  }

  table {
    tbody {
      tr {
        height: 72px !important;
      }
      .data-broker-row {
        min-width: 165px;
        .data-broker-transfer-rate {
          color: $primary-blue;
        }
      }
      .syncTrigger {
        min-width: 100px;
        flex-grow: 2;
        max-width: 150px;
      }

      .more-action {
        min-width: 100px;
        flex-grow: 2;
        .more-info-trigger {
          text-decoration: underline;
          color: $primary-blue;
          cursor: pointer;
          font-weight: 600;
        }
      }
      .endpoint-container {
        .target-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .endpoint-cell {
          display: flex;
          flex-direction: column;
          .endpoint-info {
            display: flex;
            align-items: center;
            flex: 1;
            max-width: 400px;
            @include nowrap();
            @media screen and ( max-width: 1800px ) {
              width: 230px;
            }
            .endpoint-text {
              @include nowrap();
            }
            .icon {
              margin-right: 10px;
              display: flex;
              svg {
                width: 46px;
              }
            }
          }
        }
      }

      .sync-status {
        @include sync-status();
        .status-details {
          max-width: 190px;
          width: 190px;
          display: flex;
          justify-content: space-between;
          .status-txt {
            padding-right: 5px;
            display: flex;
            align-items: center;
            .cm-integration & {position: relative}
            svg {
              width: 22px;
            }
          }
          .status-info {
            display: flex;
            align-items: center;
          }
        }
      }

      .remaining-time {
        font-style: italic;
        font-size: 14px;
        @include nowrap(100%);
        color: $grey-7-primary-text;
      }
    }
  }
}
