@import "../../../variables";
@import "../../../mixins";

$small-screen-height: 470px;

.select-direction-step{
  @include wizard-step-animation-up();
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  .scrollable-area.main {
    padding: 2px 0;
    min-width: 655px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .step-content {
    min-height: 564px;
    display: flex;
    gap: 38px;
    width: 1236px;
    padding-top: 20px ;
    margin: 0 auto;
    &.small-screen {
      min-height: $small-screen-height;
      gap: 10px;
    }

    .left-pane {
      display: flex;
      flex-direction: column;
      gap: 0;
      transition: gap $default-transition-timing;
      &.with-encryption {
        gap: 20px;
      }

      &.small-screen {
        height: $small-screen-height;
        .encrypted-checkbox-wrapper {
          left: -6px;
        }
      }

      .scrollable-area.endpoints {
        padding-top: 0 !important;
        padding-bottom: 2px;
      }
      .end-points-container {
        display: flex;
        flex-direction: column;
        gap: 36px;
        width: 972px;
        height: 655px;
        padding: 36px 44px 41px 45px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        .end-point-group {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .title {
            width: 100%;
            justify-content: flex-start;
            font-size: 15px;
            font-weight: 600;
            line-height: 2;
            text-align: left;
            color: #404040;

          }
          .end-points-list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            .end-point-wrapper {
              width: 160px;
              height: 100px;
              padding: 16px 12px 14px;
              border-radius: 4px;
              box-shadow: 2px 2px 4px 0 rgba(187, 187, 187, 0.5);
              border: solid 1px $grey-1;
              background-color: $white;
              display: flex;
              flex-direction: column;
              &.default:hover {
                border: solid 2px $dark-blue;
              }
              &.disabled {
                border: solid 1px $grey-1;
                cursor: not-allowed;
              }
              .end-point {
                align-self: center;
                svg {
                  width: 65px;
                  height: 41px;
                  &.hidden {
                    display: none;
                  }
                }
              }
              .text {
                text-align: center;
                font-size: 12px;
                line-height: 2.31;
                color: #333333;
                display: block;
                &.is-disabled {
                  color: $medium-gray;
                }
              }
            }
          }
        }
      }
      .encrypted-checkbox-wrapper {
        position: relative;
        left: -6px;
        width: 970px;
        box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
        border: solid 1px #fbfbfb;
        background-color: #ffffff;
        color: $grey-7-primary-text;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        margin: 0 auto;
        padding: 22px 34px 17px 34px;
        .title {
          border-bottom: 1px solid #d7d7d7;
          height: 44px;

          .title-right {
            position: relative;
            top: 4px;
            &:hover {
              color: $primary-blue-hover;
            }
            float: right;
            font-size: 14px;
            color: $primary-blue;
            cursor: pointer;
          }
          .lock-icon {
            padding-right: 16px;
          }
          .title-text {
            font-size: 16px;
            line-height: 1.38;
            text-align: left;
            color: $dark-blue;
            position: relative;
            top: -3px;
          }
        }

        .radio-buttons-line {
          padding-top: 10px;
          display: flex;
          gap: 37px;
          .radio-buttons-wrapper {

            .radio-button {
              padding-left: 2px;
              display: inline-block;
              line-height: initial;
              font-size: 15px;
              input {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.71;
                color: $dark-gray;
                position: relative;
                top: 2px;
                left: 8px;
              }
            }
          }
        }
      }

    }
    .select-container {
      width: 268px;
      padding-right: 54px;
      padding-left: 54px;
      border-radius: 4px;
      box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 0;
      &.small-screen {
        height: $small-screen-height;
        gap: 0;
        padding-top: 24px;
      }
      align-items: center;
      .arrow-down {
        &.big {
          height: 50px;
        }
        &.small {
          position: relative;
          top: -30px;
          height: 20px;
          &.lower {
            top: 0;
          }
          &.laptop {
            top: -15px;
          }
          &.higher {
            top: -48px;
          }
        }
        display: flex;
        flex-shrink: 4;
        align-items: center;
        width: 15px;
      }
    }
  }

}
