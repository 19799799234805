@import "./../../variables";

.search-widget {
  position: relative;
  top: 1px;
  right: 1px;
  height: 37px;
  width: 23px;
  transition: width $default-transition-timing;
  overflow: hidden;
  display: flex;
  align-items: center;

  button.open{
    svg {
      width: 23px;
      path {
        fill: $primary-blue;
        .darkula & {fill: $darker-blue}
      }
    }
  }

  ::-webkit-input-placeholder { color:#b7b7b7; font-weight: lighter;}
  ::-moz-placeholder { color:#b7b7b7; font-weight: lighter;}
  :-ms-input-placeholder { color:#b7b7b7; font-weight: lighter;}
  input:-moz-placeholder { color:#b7b7b7; font-weight: lighter;}

  input{
    width: calc(100% - 56px);
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: $grey-7-primary-text;
    .darkula & {color: $darker-blue}
  }

  &:not(.open) {
    &:hover{
      button.open svg path{
        fill: $primary-blue-hover;
        .darkula & {fill: $darker-blue}
      }
    }

    input, button.close{
      display: none;
    }
  }

  button.close{
    svg{
      width: 13px;
      height: 13px;
      fill: #b7b7b7;
    }
  }

  &.open{
    width: 300px;
    border-bottom: 2px solid $primary-blue-hover;
  }
}