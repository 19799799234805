@import "../../../variables";

.add-license {
  width: 869px;
  padding: 15px 0;

  .columns {
    padding-left: 15px;
    padding-right: 0;
  }
  .add-nss {
    margin-bottom: 12px;
    .Suc-button-base {
      color: $primary-blue;
      border-bottom: 1px solid $primary-blue;
      &:hover {}
      cursor: pointer;
    }
  }
  .license-row {
    display: flex;
    justify-content: space-between;
    .serial-number-container {
      display: flex;
      align-items: center;
      .serial-number {
        width: 220px;
      }
      #small-loader {
        margin-left: 30px;
      }
      .nss-credentials-container {
        display: flex;
        .nss-credentials {
          width: 150px;
          margin-left: 15px;
        }
      }
      .nss-credentials-select {
        width: 300px;
        margin-left: 15px;
      }
    }

    .buttons {
      margin-top: 30px;
      display: flex;
      gap: 10px;
      button {
        min-width: 140px;
        &.secondary {
          margin-left: 13px;
        }
      }
    }
  }

  .page-error {
    margin-top: 5px;
    color: $error;
    font-size: 13px;
    font-weight: 600;
  }
}
