@import "../../variables";

.cm-dashboard-tabs {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  height: 100%;
  div.container{
    display: flex;
    padding: 0px 40px 0px 112px;
    justify-content: center;
    gap:40px;

    .link {
      height: 100%;
      line-height: 1.33;
      font-size: 15px;
      color: $primary-blue;
      align-items: center;
      display: flex;
      position: relative;

      &.is-disabled {
        opacity: 0.5;
        cursor: default;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: calc(100% + 16px);
        height: 4px;
        left: -8px;
        transform: scaleX(0);
        background-color: #1fa0ff;
      }

      &:hover::after {
        transform: scaleX(1);
        transition: transform 0.4s cubic-bezier(.25, .8, .25, 1);
      }
    }

    .active {
      color: #1fa0ff;
      font-weight: 600;

      &::after {
        transform: scaleX(1);
      }
    }

  }
  .enforcement-button-container {
    display: flex;
    align-items: center;
  }
  .main-menu {
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-top:8px;
    }

    .popover-trigger-container {
      &.active {
        .vertical-context-menu {
          background-color: $dark-blue;
        }
      }

      .vertical-context-menu {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        transform: rotate(90deg);
        border: 1px solid $primary-blue;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: $dark-blue;

          svg {
            fill: $primary-blue;
          }
        }
      }
    }
  }
}
