@import "../../variables";

.error-item {
  width: 100%;
  background-color: $white;
  padding: 18px 16px 17px 27px;
  box-shadow: $box-shadow-default;
  margin-bottom: 9px;

  .error-title {
    display: flex;
    justify-content: space-between;

    .title-and-icon {
      display: flex;
      line-height: 1;

      .notification-icon {
        margin-right: 15px;
        display: flex;
        align-items: center;
        svg {
          width: 22px;
          height: 22px;
        }
      }
      .error-text {
        align-self: center;
        color: $grey-7-primary-text;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        max-width: 440px; // for the case of single error, when there's no caret
      }
    }

    button .caret-icon {
      &.open {
        transform: rotateX(180deg);
      }
      svg {
        width: 14px;
        fill: $grey-5;
      }
      transition: transform 0.3s linear;
      margin-left: 10px;
    }
  }

  .error-expanded {
    background-color: $grey-3;
    border-radius: 2px;
    margin-top: 10px;
    margin-right: 14px;
    padding: 4px 38px 7px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    position: relative;
    word-break: break-word;

    .error-expanded-message {
      p {
        font-weight: bold;
        margin-top: 12px;
        margin-bottom: 6px;
      }
    }

    .error-copy {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background-color: $primary-blue;
      color: $white;
      border-radius: 2px;
      width: 80px;
      height: 32px;
      padding: 7px 14px;

      .copy-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      svg {
        width: 14px;
      }

      .copy-text {
        font-weight: bold;
        font-size: 13px;
        line-height: 1.5;
      }
    }
  }

}
