@import "./../../../variables";
@import "./../../../mixins";

body.tab #root .reports-dashboard table.standard {
  height: 55px;
}

.Suc-tooltip-container.Suc-menu-popover.menu-popover {
  width: 132px;
  .Suc-tooltip-body ul li {
    height: 50px;
  }
  button {
    font-size: 13px;
    line-height: 1.46;
    color: $dark-gray;
    min-width: 60px;
    &:hover{
      background: $bg-grey-2;
    }
    &:focus {
        background: $bg-grey-2;
    }
  }

}

.reports-notification.notification-item {
  position: fixed;
  bottom: 80px;
  width: 840px;
  left: calc(50% - 420px);
}

.reports-dashboard {
  max-width: $dashboard-width;
  margin: 32px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sort-button {
    svg {
      width: 16px;
      height: 13px;
    }
  }
  .endpoint-report {
    display: flex;
    margin-top: 7px;
    .icon {
      margin-right: 5px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .endpoint-name {
      line-height: 40px;
      @include nowrap();
    }
  }
  .report-status {
    line-height: 18px;
    display: flex;
    svg {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin-right: 11px;
    }

    #small-loader {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin-right: 11px;
    }

    .Suc-tooltip-info-container {
      svg {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 11px;
        fill: $error-color;
      }
    }
  }
  .reports {
    .buttons-list {
      display: flex;
      justify-content: flex-start;
      .Suc-button.Suc-secondary.reporter-button {
        background-color: white;
        &.activate {
          border-radius: 16px;
          min-width: 128px;
          height: 32px;
        }
        &:hover {
          background-color: white;
        }
        .Suc-button-spinner-loader.Suc-button-loader {
          svg {
            margin-top: 1px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            .spinner_svg__primary-stroke {
              stroke: $dark-blue;
            }
          }
        }
      }
    }
    .menu-popover {
      display: flex;
      justify-content: center;
    }
  }
  .no-reports {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 126px auto 0 auto;
    svg {
      width: 82px;
      height: 82px;
      margin: auto;
    }
    .no-reports-title {
      margin-top: 20px;
      font-size: 24px;
      text-align: center;
      color: #006cd0;
    }
    .no-reports-info {
      width: 350px;
      margin: 30px auto;
      font-size: 14px;
      line-height: 1.86;
      text-align: center;
      color: $grey-7-primary-text;
    }
  }
}

