@import "../../../mixins";

#root .hostsSelect{
  width: 100%;
  margin-left: 0;
  table.standard.selectable tbody {
    tr {
      height: 60px;
      border-top-width: 5px;
      .checkmark {
        width: 50px;
        svg {
          vertical-align: middle;
        }
      }
      .host-name {
        text-align: left;
        div {
          max-width: 332px;
          padding: 0 20px;
          @include nowrap(100%);
        }
      }
    }
  }
}
