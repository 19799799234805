@import "../../../variables";
@import "../../../mixins";

form{
  .radio-buttons {
    height: 50px;
    &:hover:not(.disabled) {
      label, input {
        cursor: pointer;
      }
    }
    &.disabled label {
      color: $medium-gray;
    }
  }

  .checkbox-inputs {
    &.disabled span{
      color: $medium-gray;
    }
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: #006dc9;
    &+.MuiSwitch-track {
      background-color: #93b3e5;
    }
  }

  .button-group>button.submit{
    margin-top: 0;
    @media screen and ( max-width: $small-resolution ){
      margin-top: 0;
    }
  }

  .button.submit{
    margin-top: 65px;

    @media screen and ( max-width: $small-resolution ){
      margin-top: 14px;
    }
  }

  .field-container{
    margin-bottom: 2rem;
    position: relative;
    input:disabled {
      @include disabledField();
    }
    .label-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  input.with-error {
    border-width: 2px;
    border-color: #da1e27;;
  }

  .small-error .form-error{
    font-size: 0.76rem;
  }

  .form-error{
    font-weight: 600;
    position: absolute;
    width: 100%;
    margin-top: -14px;
    color: $error;

    &.radio {
      margin-top: 26px;
    }
  }

  .extra {
    color: $grey-7-primary-text;
    font-size: 0.76rem;
    position: relative;
    top: 4px;
  }

  p{
    font-size: 13px;

    a{
      font-size: 13px;
      color: $primary-blue;
    }
  }
}

//inline input is not in the design system
.Suc-text-input.inline {
  height: auto;
  .Suc-input-top {
    padding-bottom: 0;
  }
  .Suc-input-field {
    height: 24px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: unset;
    border-bottom-color: $grey-4-disabled;
    padding: 11px 15px;
  }
}


