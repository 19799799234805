@import "../../../variables";
@import "../../../mixins";


.filter-widget-container {
  position: relative;
  .filter-button {
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 34px;
    }
    svg {
      width: 18px;
      height: 18px;
      position: relative;
      left: 5px;
      g {
        fill: #ffffff;
      }
    }
    &.active {
      g {
        fill: #163f68;
      }
    }
  }
  .filter-widget {
    position: absolute;
    width: 250px;
    top: 55px;
    right: 0;
    padding: 20px 20px 12px 20px;
    border-radius: 2px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.17);
    background-color: $white;
    z-index: $popover-z;
    .filters-list {
      height: auto;
      max-height: 170px;
      margin-bottom: 30px;
      overflow-y: auto;
      label {
        @include nowrap();
        &:first-child {//this is a selector for "all" line
          margin-bottom: 10px;
        }
      }
      @include default-scrollbar();
      .value {
        font-size: 14px;
        color: $dark-gray;
        margin-bottom: 15px;
        input {
          margin-right: 10px;
        }
      }
    }
    .buttons-row {
      display: flex;
      border-top: 1px solid $light-gray;
      padding-top: 10px;
    }
    button {
      font-size: 13px;
      text-align: center;
      color: #3d84ff;
      height: 22px;
      width: 50%;
      &:disabled {
        cursor: not-allowed;
        color: #999998;
      }
      &:hover:not(:disabled) {
        color: scale-color(#3d84ff, $lightness: -15%);
      }
    }
  }
}

