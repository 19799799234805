@import "../../../variables";
$end-point-width: 235px;
$end-point-height: 110px;
$icon-size: 38px;
$defaultAnimationDuration: 500ms;
$defaultAnimationDelay: 100ms;

.add-end-point {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .small-screen & {
  }
  .add-box {
    width: $end-point-width;
    height: 213px;
    box-shadow: none;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: $darker-blue;

    .dropped-endpoint-container {
      transition: opacity $defaultAnimationDuration ease-in-out;
      opacity: 0;
      &.show {
        opacity: 1;
      }
    }
    .dropped-endpoint {
      display: flex;
      flex-direction: column;
      position: relative;
      .provider-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 160px;
        height: 100px;
        .small-screen & {
          height: 80px;
        }

        border-radius: 4px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #fbfbfb;
        background-color: #ffffff;
        .top-area {
          position: absolute;
          top: 6px;
          right: 6px;
          height: 28px;
          z-index: 1000;
          svg.unselect-icon {
            margin-top: 6px;
            margin-right: 6px;
            width: 16px;
            height: 16px;
            fill: $grey-7-primary-text;
            &:hover {
              fill: $end-point-hover-color;
              cursor: pointer;
            }
          }
        }

        .provider-box-icon {
          display: flex;
          justify-self: center;
          svg.provider-icon {
            width: 41px;
            height: 41px;
            display: block;
            margin: auto;
          }
        }
      }
      .endpoint-name {
        margin-top: 17px;
        font-size: 14px;
        font-weight: 600;
        line-height: 2.14;
        text-align: center;
        color: $dark-gray;
      }
    }
    .endpoint-protocol {
      display: flex;
      flex-direction: column;
      .small-screen & {
        margin-top: -12px;
      }
      .select-text {
        font-size: 14px;
        text-align: center;
        color: $dark-gray;
        margin-top: 12px;
      }
      .protocol-options {
        display: flex;
        justify-content: space-between;
        width: 108px;
        margin: 9px auto 0 auto;
        button.protocol {
          width: 48px;
          height: 24px;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          border-radius: 4px;
          border: solid 1px $dark-blue;
          color: $dark-blue;
          &.selected {
            background-color: $dark-blue;
            color: $white
          }
        }
      }

    }


    div.drop-box {
      width: 160px;
      height: 100px;
      margin: 0 0 16px;
      padding: 31px 61px;
      border-radius: 4px;
      box-shadow: 2px 2px 6px 0 #bbbbbb;
      border: dashed 1px $darker-blue;
      background-color: $secondary-hover-fill;
      .small-screen & {
        height: 80px;
        padding-top: 21px;
      }
      &.is-hover {
        box-shadow: 4px 4px 15px 0 #bbbbbb;
      }
    }
    svg.add-box-svg {
      fill: $darker-blue;
      width: $icon-size;
      height: $icon-size;
      margin-bottom: 15px;
    }
    .text {
      font-size: 14px;
      font-weight: 600;
      line-height: 2.14;
      color: #333333;
      text-align: center;
      &.is-hover {
        color: $primary-blue;
      }
    }
  }
  .loader {
    width: $end-point-width;
    height: $end-point-height;
    background-color: $white;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    padding-top: 80px;
  }
}
