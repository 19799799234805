@import "../../../variables";
@import "../../../mixins";

#root .relationship-step {

  .review-endpoint-card {
    box-shadow: 0 2px 2px 0 rgba(187, 187, 187, 0.5);
    overflow: hidden;
    position: relative;
    padding: 25px 30px;
    width: 519px;
    height: 190px;
    background-color: $white;
    display: flex;
    flex-direction: column;

    .endpoint {
      display: flex;
      align-items: center;
      .details {
        margin-left: 15px;
        .name {
          color: $primary-blue;
          font-size: 14px;
          font-weight: bolder;
          margin-bottom: 6px;
          line-height: 16px;
        }
        .description {
          color: $grey-7-primary-text;
          max-width: 300px;
          @include nowrap();
        }
      }
    }

    .endpoint-root {
      svg {
        width: 75px;
      }
    }

    .endpoint-directory {
      margin-left: 31px;
      svg {
        width: 113px;
      }
    }
  }
}
