@import "./../../variables";

.view-toggle-widget{
  svg{
    fill: $primary-blue;
    width: 24px;
    height: 24px;
  }

  button{
    position: relative;
    top: 7px;
    margin-left: 10px;
  }
}