@import '../../../variables';
@import '../../../mixins';

$card-height: 90px;
#root .card-container .anf-we-card {
  border: solid 1px transparent;
  position: relative;
  height: $card-height;
  background-color: #ffffff;
  margin-bottom: 20px;
  box-shadow: $box-shadow-default;
  .card-body {
    width: 100%;
    height: 100%;
  }

  .checkmark {
    display: none;
    position: absolute;
    top: -14px;
    left: -12px;
    svg {
      width: 30px;
    }
  }
  &:hover {
    box-shadow: 10px 12px 40px 0 rgba(187, 187, 187, 0.5);
  }

  &.selected {
    border-color: $primary-blue;

    .checkmark {
      display: inline-block;
    }
  }
  .card-content {
    width: 100%;
    display: flex;
    align-items: center;
    .card-icon  {
      display: flex;
      align-items: center;
      margin: 0 10px;
      svg {
        width: 80px;
      }
    }
    .card-details {
      display: flex;
      flex-direction: column;
      .we-name {
        color: $cm-text-color;
        font-weight: 600;
        align-self: flex-start;
      }
      .we-details {
        display: flex;
        width: 100%;
        justify-content: space-around;
        .card-detail {
          width: 150px;
          text-align: initial;
          padding: 10px 20px 10px 0;
          margin-right: 20px;
          .value {
            font-weight: 600;
            width: 100%;
            @include nowrap();
            margin-bottom: 5px;
          }
          &:not(:last-child) {
            border-right: 1px solid $light-gray;
          }
          .name {
            color: $grey-6;
          }
        }
      }
    }
  }
}
