@import "./../../../variables";
@import "./../../../mixins";

.report-errors {
  max-width: $dashboard-width;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  .bread-crumbs {
    display: flex;
    margin-bottom: 11px;
    margin-top: 24px;
    font-size: 14px;
    color: $primary-blue;
    .back {
      &:hover {
        color: $primary-blue-hover;
        cursor: pointer;
      }
    }
    .last {
      color: $dark-gray;
    }
    .right-arrow {
      height: 20px;
      svg {
        vertical-align: middle;
        width: 8px;
        height: 12px;
        margin-right: 11px;
        margin-left: 11px;
      }
    }
    .path {
      max-width: 600px;
      @include nowrap();
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    height: 75px;
    box-shadow: $box-shadow-default;
    background-color: white;
    margin-bottom: 20px;
    padding: 10px 40px;
    .header-info {
      display: flex;
    }
  }

  .summary-graphs {
    display: flex;
    justify-content: space-between;
    height: 280px;
    color: $primary-blue-hover;
    gap: 20px;
    margin-bottom: 40px;
    .pie-chart .legend-area .legend-area-container .legend-wide .legned-with-icon svg {
      .st-directories {
        fill: $primary-color;
      }
    }
  }
  .failed-files-table {
    .table-row {
      .value {
        font-weight: 600;
        font-size: 18px;
        color: $alert-color;
      }
    }
    .table-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
      h5 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .table-no-data {
      width: 100%;
      margin-top: 77px;
      color: #898989;
      text-align: center;
      line-height: 2;
    }
  }

}
