@import "./slider-from-right-mixin";

.right-panel {
  @include sliderFromRight(1000px, 0);
  .right-panel-content {
    width: 1000px;
    height: 100vh;
    background-color: #f8f8f8;
  }
}
