@import "../../variables";
@import "../../mixins";

$failed-color: $error;

.pie-chart-container {
  flex: 1;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  .chart-title {
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0;
    border-bottom: 1px solid $grey-4-disabled;
    color: $primary-blue;
  }
  .chart-details {
    display: flex;
    height: 100%;
  }
  .pie-chart {
    position: relative;
    width: 228px;
    margin-top: 30px;
    left: -35px;

    canvas {
      position: absolute;
      top: -30px;
      left: 25px;
      height: 200px !important;
      width: 200px !important;
    }
    .inside-pie {
      position: absolute;
      width: 110px;
      text-align: center;
      top: 42px;
      left: 69px;
      .big-text {
        color: $failed-color;
        font-weight: 600;
        font-size: 24px;
      }
      .small-text {
        font-size: 13px;
      }
    }
  }
  .legend-area {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    .legend-area-separator {
      border-left: 1px solid $grey-3;
      height: 147px; //should be the same size as the pie-chart diameter
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .legend-area-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      .subtitle {
        font-weight: 600;
        svg {
          width: 42px;
          margin-right: 10px;
          &.file {
            width: 29px;
          }
          .st-directories {
            fill: $primary-blue-hover;
          }
          .st-files {
            fill: $primary-blue-hover;
          }
        }
      }
      .legend {
        display: flex;
        gap: 30px;
        .legend-with-icon {
          display: flex;
          svg {
            width: 42px;
            .st-directories {
              fill: $primary-blue-hover;
            }
            .st-files {
              fill: $primary-blue-hover;
            }
          }
          .legend-unit {
            display: flex;
            flex-direction: column;
            .value {
              font-size: 20px;
              font-weight: 600;
              line-height: 1;
            }
            .status:before {
              content: ' \25CF';
              font-size: 18px;
              margin-right: 4px;
            }
            .status.failed:before {
              color: $failed-color;
            }
            .status.success:before {
              color: $primary-blue-disabled;
            }
          }
        }
        &.legend-wide {
          justify-content: flex-start;
          gap: 40px;
          .legend-with-icon:not(:first-of-type) {
            border-left: 1px solid $grey-3;
            padding-left: 40px;
          }
          .legend-with-icon {
            svg {
              .st0 {
                fill: $primary-blue-hover;
              }
            }
          }
          .legend-unit {
            justify-content: flex-end;
            margin-left: 20px;
            .value {
              font-size: 30px;
              line-height: 1.3;
            }
            .status {
              font-size: 13px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
