@import "../../../variables";
@import "../../../mixins";

.cm-integration #root table.standard.selectable tbody{
  tr.selected {
    td {
      color: $primary-blue;
      &:not(.additional) {
        font-weight: normal;
      }
    }
  }
  tr.disabled {
    background-color: #f9f8f8;
    color: #989797;
    &:hover {
      background-color: #f9f8f8;
      cursor: initial;
    }
  }
  tr:hover:not(.disabled) .check {
    > * {
      display: block;
    }
  }
  tr:hover:not(.selected) .check:not(:hover){
    svg{
      fill: #ccc;
    }
  }
  tr:not(.disabled) td.check:not(.plus-button){
    width: 76px;
    padding-left: 24px;
    padding-top: 9px;
    svg {
      width: 28px;
      height: 28px;
      fill: $primary-blue;
    }
    &:hover{
      svg{
        fill: $primary-blue-hover;
      }
    }
  }
  tr:not(.disabled) td.check.plus-button{
    svg {
      width: 28px;
      height: 28px;
    }
    &:hover{
      svg{
        fill: $primary-blue;
      }
    }
  }
}
