@import "../../variables";
@import "../../mixins";

.data-broker-wizard {
  .data-broker-wizard-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    overflow: hidden;
    .title {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin: 40px auto;
      font-size: 20px;
      color: $primary-blue;
      span {
        margin-left: 5px;
      }
    }
    .content-area {
      flex-grow: 1;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include default-scrollbar();
    }
    .button-with-notification {
      width: 100%;
    }
  }
  > .previous-step {
    align-self: flex-start;
    height: 50px;
    margin-left: 50px;
  }
}
