@import "../../../variables";

.Suc-default-dialog-layout.delete-group-dialog {
  min-width: 600px;
  p {
    color: #434343;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.5;
  }
  .confirm-field {
    border-top: 1px solid #a5a5a5;
    padding-top: 10px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #434343;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.5;


    .Suc-text-input.Suc-bottom-margin {
      margin-bottom: 0;
    }
  }
}
