@import "../../variables";

.data-sense-info {
  display: flex;
  svg.more-info-icon {
    position: relative;
    top: 5px;
  }
  &.from-header {
    position: fixed;
    bottom: 30px;
    left: 17%;
    cursor: default;
    svg.more-info-icon {
      margin-right: 6px;
    }
  }
  &.from-card {
    cursor: pointer;
    margin-left: 3px;
    max-width: 330px;
    svg.more-info-icon {
      margin-left: 6px;
    }
  }
  color: $dark-blue;
  size: 14px;
  font-weight: bolder;
}
