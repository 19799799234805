@import "../../../variables";

.wizard-step-header {
  margin: 0 75px;
  display: flex;
  align-items: center;
  @media screen and ( max-width: 1400px ){
    margin: 0 70px;
  }
  .step-header-item {
    flex: 1;
    display: flex;
    justify-content: center;
    &.header-title {
      //the title needs to take more place than the side actions
      flex: 2 1;
      display: flex;
      align-items: center;
      h3 {
        margin-bottom: 0;
      }
    }
  }
  .align-to-right {
    margin-left: auto;
  }
  .align-to-left {
    margin-right: auto;
  }
}
