@import "../variables";
@import "../mixins";

body{
  transition: background-color $default-transition-timing;
  font-size: 14px;
  color: $grey-7-primary-text;
}

//TODO can be deleted after this is added to the shared repository
.Suc-button {
  display: inline-flex;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
}

.hidden{
  opacity: 0;
}

*:focus{
  outline: none;
}

#root{
  height: 100%;

  .is-invalid-input:not(:focus){
    background-color: $white;
  }
}

button.disabled{
  cursor: default;
}

.row-medium{
  @include flex-grid-row(null, 64rem, null, true) { }
  &.step-container{
    margin-top: -35px;
  }
  .columns{
    @include flex-grid-column(null, 80px);
  }
}

svg {
  fill: $primary-blue;
}

a {
  color: $link-color;
  &.text-button {
    font-weight: 600;
  }
  &:hover {
    color: $link-hover;
  }
}

button.info {
  display: flex;
  svg {
    width: 15px;
    fill: $tooltip-icon-default;
    &:hover {
      fill: $tooltip-icon-hovered;
    }
  }
}

#root button.text-button {
  color: $link-color;
  font-weight: 600;
  &:hover {
    color: $link-hover;
  }
  &:disabled {
    color: #93b3e5;
  }
}

button.text-button {
  color: $primary-blue;
  &.hover {
    color: $primary-blue-hover;
  }
}

button.help{
  svg{
    width: 15px;
    height: 15px;
    margin: 0;
  }
}

a.button{
  line-height: 35px;
}

.button{
  width: 175px;
  height: 35px;

  &.submit{
    margin-top: 65px;

    @media screen and ( max-width: $small-resolution ){
      margin-top: 14px;
    }
  }
}

button.underline-button {
  text-decoration: underline;
  color: $primary-blue;
  line-height: initial;
  cursor: pointer;
  &:hover, &:focus{
    color: $primary-blue-hover;
  }
}

button.outline{
  width: 175px;
  height: 35px;
  border: 1px solid $button-primary-bg;
  color: $button-primary-bg;
  background-color: transparent;
  line-height: 34px;

  &:hover, &:focus{
    border-color: $button-primary-hover-bg;
    background-color: $button-primary-hover-bg;
    color: $white;
  }
}

.wordwrap {
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.no-left-padding {
  padding-left: 0;
}

//Tooltip:
.Suc-tooltip-container {
  width: fit-content;
  max-width: 380px;
  &.text-popover {
    padding: 8px 16px;
  }
}

.Suc-tooltip-container.Suc-menu-popover.menu-popover {
  width: fit-content;
  z-index: 500;
  .Suc-menu-no-bullet {
    ul li:hover:not([disabled]) {
      background-color: $grey-1; //to override SUC definition (the disabled prop is on the button)
    }
    button {
      padding: 0 30px 0 25px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover:not([disabled]) {
        background-color: $bg-grey-2;
      }
      &[disabled] {
        cursor: default;
        filter: opacity(0.5);
      }
      a {
        display: flex;
        align-items: center;
        color: inherit;
      }

      //this is the icon
      span:first-of-type {
        width: 20px;
        transform: translateY(1px);

        svg {
          max-height: 17px;
        }
      }

      //this is the text
      span:nth-of-type(2) {
        margin-left: 16px;
      }

      .garbage-bin svg, .external-link-icon svg {
        transform: translateY(-1px);
      }
    }
  }
}

.Suc-dialog {
  z-index: 500!important;

  .Suc-dialog-content {
    max-width: 1000px;
  }
}

//****** Override foundation's defaults********

[type='text'], [type='password'] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  height: auto;
}

label {
  color: $grey-7-primary-text;
}

form p {
  font-size: 14px;
}
