@import "../../../variables";

.progress-bar {
  background-color: rgba(40, 77, 174, 0.2);
  color: $primary-blue;
  border-radius: 5px;
  height: 10px; //can be overwritten
  width: 500px; //can be overwritten
  &.progress-linear-animated {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
  }

  &.progress-linear-animated::-webkit-progress-bar {
    background-color: transparent;
  }

  /* Determinate */
  &.progress-linear-animated::-webkit-progress-value {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &.progress-linear-animated::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &.progress-linear-animated::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
  }

  /* Indeterminate */
  &.progress-linear-animated:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%);
    animation: progress-linear-animated 2s infinite linear, fade-in 1s ease 0.5s forwards
  }

  &.progress-linear-animated:indeterminate::-moz-progress-bar {
    background-color: transparent;
  }

  &.progress-linear-animated:indeterminate::-ms-fill {
    animation-name: none;
  }
}

@keyframes progress-linear-animated {
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
}