@import "../../variables";
@import "../../mixins";

.instance-details-aws {
  .form-content {
    margin-top: 40px;
    width: 800px;
    display: flex;
    justify-content: space-between;
    .col {
      width: 360px;

      .section-title {
        font-weight: 600;
        border-bottom: 1px solid $light-gray;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .radio-buttons {
        display: flex;

        > div {
          margin-right: 15px;
        }
      }

      label + .form-error {
        top: 75px;
      }
    }
  }
}

