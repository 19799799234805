.relationshipInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    svg {
        width: 41px;
    }

    .infoItemDetails {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .subTitleContainer {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            pointer-events: none;

            &.clickable {
                cursor: pointer;
                pointer-events: all;
            }

            .subTitle {
                &.disabled {
                    opacity: .5;
                }

            }

            .editIcon {
                fill: var(--white);
                width: 11px;
                height: 11px;
                margin-left: 6px;
            }
        }
    }
}