@import "../../../variables";

.Suc-tooltip-container.timing-info {
  width: 280px;
}


.sync-info {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .sync-duration {
    font-size: 13px;
    color: $dark-gray;
    position: absolute;
    right: 40px;
    top: 37px;

    &.card-view {
      top: 30px;
      right: 80px;
    }

    .duration-popover {
      display: flex;
      .Suc-popover-trigger-container {
        margin-right: 6px;
      }
    }
  }

  .phases-summary {
    display: flex;
    justify-content: space-between;
    background-color: $white;
  }

  .bottom-line {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommendations {
    }

    .links {

      display: flex;

      .info-links {
        font-size: 13px;
        font-weight: 600;
        bottom: 77px;
        cursor: pointer;
        z-index: 300;
        opacity: 0.8;

        svg {
          width: 9px;
        }

        &.card-view {
          height: 20px;
        }

        &.view-failed-files {
          color: $error;

          svg {
            margin-right: 5px;
            fill: $error;
          }

          &:hover {
            opacity: 1;
          }
        }

        &.download-logs {
          padding-right: 15px;

          button.download-logs {
            color: $primary-blue;
            font-weight: 600;

            .icon {
              top: 0;
              left: -19px;
            }

            &:hover {
              color: scale-color($primary-blue, $lightness: -20%);
            }

            &:disabled {
              color: $medium-gray;
              opacity: 0.8;
            }
          }

          svg {
            width: 14px;
            fill: $primary-blue;
          }
        }
      }
    }
  }
}

.phase-info {
  width: 430px;

  &.Copy{
    border-bottom: solid 1px $grey-3;
  }

  .phase-title {
    color: $primary-blue;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: solid 1px #163f68;
  }
}

.status-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $grey-3;
  height: 60px;

  .line-title {
    font-size: 13px;
    font-weight: 600;
  }

  &.failed .line-title {
    color: $error;
  }

  &:not(.failed) .data-display .text {
    color: $grey-6;
  }

  .details {
    display: flex;
    width: calc(100% - 130px);
    .data-display {
      flex: 1;
      width: 80px;
      padding-left: 10px;
      border-left: 1px solid $grey-3;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1;
      
      &.hidden {
        visibility: hidden;
      }

      &.failed {
        color: $error;
      }

      .value {
        font-size: 20px;
      }

      .text {
        font-size: 12px;
      }
    }
  }
}

.Suc-tooltip-container.download-logs {
  max-width: 320px;
}
