$tertiary-color: #36bbca;

@import 'settings';
@import '~foundation-sites/scss/components/button';

$primary-header-height: 56px;
$secondary-header-height: 64px;
$footer-height: 34px;

$default-transition-timing: 0.4s cubic-bezier(.25,.8,.25,1);

$default-transition: all $default-transition-timing;

$item-hover: #ececec;
$error-color: #cf0633;
$dark-blue-lighter: #20466d;

$very-light-gray: #ebebeb;
$light-gray: #d7d7d7;
$medium-gray: #b7b7b7;
$dark-gray: #636363;

$cm-text-color: #0f6ad3;
$darker-blue: #163f68;
$mouse-grey: #636363;

$footer-z: 500;
$header-z: 500;
$popover-z: 1000;
$modal-z: 2000;

$main-scroll-z: 409;

$large-padding: 80px;
$small-padding: 10px;

$min-width: 1366px;
$small-resolution: 1366px;

$border-color: #cccccc;

$byol-model-color: #01b383;
$tier-1-color: #a26ccf;
$tier-2-color: #7912d8;
$tier-3-color: #500893;

$end-point-hover-color: #59c5e9;

$light-blue: #419AE8;

$slider-width: 489px;

$default-background: $white;
$wizard-background: #f1f1f1;
$tab-background: #e6e7e8;
$form-background: $default-background;
$selector-background: $wizard-background;

$dashboard-width: 1280px;

//***********************************

//new design system brand colors:
$white: #ffffff;
$grey-1: #fbfbfb;
$bg-grey-2: #f5f5f5;
$grey-3: #E0E0E0;
$grey-4-disabled: #c8c8c8;
$grey-5: #a7a7a7;
$grey-6: #838383;
$grey-7-primary-text: #404040;

$primary-blue: #006dc9;
$primary-blue-hover: #2359a3;
$accent-blue-1: #1fa0ff;
$primary-blue-disabled: #93b3e5;
$secondary-hover-fill: #edf4ff;

//new design system notification colors:
$error: #DA1E27;
$error-light: #FFF5F5;
$warning: #EB6D12;
$warning-light: #FFF6EF;
$information: #00C4FF;
$information-light: #f0f7ff;
$success: #27D397;
$success-light: #F2FCF9;
$tooltip: #4C4E6A;

//new design system definitions:
$button-primary-bg: $primary-blue;
$button-primary-text: $white;
$button-primary-hover-bg: $primary-blue-hover;
$button-primary-disabled-bg: $primary-blue-disabled;

$button-secondary-text: $primary-blue;
$button-secondary-bg: $white;
$button-secondary-hover-text: $primary-blue-hover;
$button-secondary-hover-bg: $secondary-hover-fill;
$button-secondary-disabled-text: $grey-4-disabled;

$tooltip-icon-default: #d7d7d7;
$tooltip-icon-hovered: $primary-blue;

$tooltip-background-color: $grey-1;
$tooltip-text-color: #404040;
$tooltip-font-size: 12px;

$link-color: $primary-blue;
$link-hover: $primary-blue-hover;
$link-disabled: $primary-blue-disabled;

$notification-height: 64px;
$notification-max-height: 220px;
$notification-width: 819px;
$notification-narrow-width: 600px;
$notification-error-border: $error;
$notification-error-bg: $error-light;
$notification-success-border: $success;
$notification-success-bg: $success-light;
$notification-info-border: $information;
$notification-info-bg: $information-light;

$disabled-input-border: $grey-4-disabled;
$disabled-input-bg: $bg-grey-2;
$disabled-text: $grey-5;

$table-header-color: $accent-blue-1;

$box-shadow-default: 2px 2px 6px 0 rgba(200, 200, 200, 0.5);
