.provider-login-layout {

  .title > svg {
    width: 45px;
    margin-right: 10px;
  }

  p {
    margin-bottom: 10px;
    width: 690px;
    text-align: center;
    line-height: 1.8;
  }
  .notification-item {
    position: absolute;
    bottom: 100px;
  }
}