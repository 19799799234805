@import "../../variables";

.create-folder-row {

  .target-folder-name {
    display:table-cell;
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
    }
  }

  .clear-button {
    border-left: none !important;

    svg {
      width: 24px;
      height: 24px;
      fill: $dark-gray;
      margin-bottom: 3px;
    }
  }
}