@import "./../../variables";

.sync-header {
  display: flex;
  color: $white;
  height: 70px;
  background-color: $primary-blue;
  z-index: 499;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .sync-header-left {
    display: flex;
    align-items: center;
    margin-left: 40px;
    svg.sync-cloud {
      fill: $white;
      width: 27px;
    }
    .title {
      font-size: 1.3vw; //adjust to width of the screen
      font-weight: lighter;
      margin-left: 30px;
    }
  }
  a.close {
    margin-right: $large-padding;
    svg {
      width: 30px;
      fill: #7acaf3;
    }
  }
  @media screen and (max-width: $small-resolution) {
    .sync-header-left {
      .title {
        font-size: 26px;
      }
    }
    a.close {
      margin-right: $small-padding;
    }
  }
}