@import "../../../variables";

@keyframes move-it-move-it-button{
  0%   {width: 0; left: 0;}
  100%  {width: 100%; left: 0;}
}

@keyframes move-it-move-it {
  0%   {width: 1%; left: 0;}
  70%  {width: 35%; left: 45%}
  100% {width: 1%; left: 99%}
}

.button .loading-bar{
  background-color: transparent;
  border-bottom: none;

  .the-blue{
    background-color: #30a3b0;
    animation: move-it-move-it-button 1.5s infinite;
    animation-timing-function: linear;
    bottom: 0;
  }
}

.loading-bar{
  height: 5px;
  position: absolute;
  width: 100%;
  left: 0;
  background-color: #d6d8db;
  border-bottom: 2px solid #cbcdd0;

  &.top{
    top: 0;
  }

  &.bottom{
    bottom: 0;
    left: 0;
  }

  .the-blue{
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 10%;
    height: 5px;
    background-color: #175994;
    .cm-integration & {
      background-color: $primary-blue;
    }
    animation: move-it-move-it 1.5s infinite;
    animation-timing-function: linear;
  }
}