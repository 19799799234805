@import "./../../../variables";
@import "./../../../mixins";

#root .managed-data-broker {
  width: 1131px;
  margin: 0 2px 8px 5px;
  padding: 0 40px;
  background-color: #f5f5f5;
  &:first-child {
    margin-top: 30px;
  }

  .broker-footer-warning {
    height: 49px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
    .notice-container {
      justify-content: start;
    }
  }

  .managed-data-broker-collapsed-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 81px;
    padding: 20px 0;
    .managed-data-broker-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      min-width: 760px;
      .name-icon {
        display: flex;
        align-items: center;
        position: relative;
        svg {
          position: absolute;
          left: -20px;
          width: 50px;
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          max-width: 300px;
          line-height: 1.8;
          @include nowrap();
          padding: 0 32px 0 32px ;
          width: 260px;
          &.disabled {
            filter: opacity(0.4);
          }
        }
      }
      .managed-header-text {
        width: 152px;
        padding: 0 16px;
        font-size: 16px;
        line-height: 1.38;
        color: $tooltip-text-color;
        text-align: center;
        &:not(:last-child) {
          border-right: 1px solid $tooltip-text-color;
          border-left: 1px solid $tooltip-text-color;
        }
        &.wider {
          width: 250px;
        }
        &.disabled {
          filter: opacity(0.4);
        }
      }

    }
    .managed-data-broker-header-status {
      display: flex;
      align-items: center;
      width: 250px;

      .broker-status-display {
        .icon {
          margin-right: 8px;
          svg {
            width: 22px;
            height: 22px;
          }
          .circled {
            @include circledBorder(22px, $error-color);
          }
        }

        .text {
          margin-bottom: 0 !important;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.38;
          color: $tooltip-text-color;
        }
      }


    }
    .managed-data-broker-buttons {
      display: flex;
      justify-content: flex-end;
      width: 66px;
      .managed-data-broker-collapse {
        &:hover {
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        z-index: 499;
        margin-right: 16px;
        svg {
          width: 25px;
          height: 25px;
          fill: #b9b9b9;
        }
      }
      .garbage-bin {
        svg {
          max-width: 17px;
        }
      }
    }

    .Suc-popover-trigger-container.Suc-menu-trigger {
      display: flex;
      align-items: center;
      z-index: 499;
      line-height: 1;
      svg.Suc-circle-trigger {
        width: 25px;
        height: 25px;
      }
    }

  }

  .managed-data-broker-content {
    .placement-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 73px;
      border-top: 1px solid rgba(151, 151, 151, 0.3);
      .placement-title {
        min-width: 132px;
        width: 132px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: $tooltip-text-color;
      }

      .placement-double-line {
        width: 200px;
        &.full-width {
          width: 100%;
        }
        &:nth-child(6) {
          width: 128px;
        }
        .placement-dl-value {
          padding: 0 32px 0 0;
          font-size: 13px;
          @include nowrap();
        }
        .placement-dl-title {
          display: flex;
          gap: 7px;
          font-size: 12px;
          color: #838383;
          padding: 0 32px 0 0;
        }
      }

    }
  }
}
