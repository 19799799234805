@import "./../../variables";
@import "./../../mixins";

:root {
  --margin-from-number: 18px;
  --margin-from-next-step: 48px;
  --steps-width: 900px;
}
@media screen and ( max-width: 1600px ) {
  :root {
    --margin-from-number: 8px;
    --margin-from-next-step: 30px;
    --steps-width: 750px;
  }
}

.wizard-header-new {
  position: fixed;
  top: 0;
  height: $primary-header-height;
  z-index: 499;
  left: 400px;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  color: $primary-blue;
  button[disabled] {
    cursor: default;
  }
  .steps {
    display: flex;
    justify-content: center;
  }

  .steps-with-arrows {
    display: flex;
    justify-content: center;
    position: relative;
    width: var(--steps-width);
    .arrow {
      display: flex;
      align-items: center;
      position: absolute;
      top: 7px;
      left: var(--steps-width);
      z-index: 2;
      svg {
        width: 9px;
        path {
          fill: $primary-blue;
          stroke: $primary-blue;
        }
      }
      &.left {
        left: -40px;
        transform: rotate(180deg);
      }
      &:hover svg path {
        fill: $primary-blue-hover;
        stroke: $primary-blue-hover;
      }
    }
  }
}

@media screen and ( max-width: 1400px ) {
  .wizard-header .steps-with-arrows {
    .arrow.left {
      left: -20px;
    }
    .steps .step .step-name {
      max-width: 140px;
    }
  }
}

