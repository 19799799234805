@import "../../../variables";

#root .loading-icon{
  color: $darker-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    fill: $darker-blue;
    margin-bottom: 10px;
    width: 70px;
  }
  .text{
    font-size: 14px;
  }
}