@import "../../../variables";
@import "../../../mixins";

.broker-status-display {
  display: flex;
  align-items: center;
  font-weight: 600;
  .icon {
    margin-right: 3px;
    display: flex;
  }
  &.error {
    color: #db1b28;
    .icon .circled {
      @include circledBorder(23px, #db1b28);
    }
  }
  &.unknown {
    .icon svg{
      width: 20px;
      fill: #b7b7b7;
      margin-right: 1px;
    }
  }

  &.in-progress {
    color: $primary-blue;
    .icon {
      align-self: baseline;
    }
  }

  &.success {
    color: #27d397;
    .icon {
      svg {
        width: 24px;
      }
    }
  }
  &.disabled {
    .icon {
      svg {
        width: 24px;
      }
    }
  }

  .Suc-popover-trigger-container {
    margin-left: 7px;
  }
}