@import "./variables";

.landing-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    display: flex;
    width: 100%;
    height: 180px;
    padding: 0 0 16px;
    box-shadow: 2px 2px 6px 0 #bbbbbb;
    background-color: #ffffff;
    justify-content: center;
    svg {
      margin: auto 70px;
    }
    .icon-with-text {
      padding-top: 51px;
      >div {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #1ca6ff;
      }
    }
  }
  .landing-content {
    display: flex;
    gap: 200px;
    justify-content: center;
    padding-bottom: 10px;
    .landing-left {
      width: 560px;
      h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.67;
        letter-spacing: normal;
        color: $primary-blue-hover;
        margin-bottom: 21px;
        margin-top: 38px;
      }
      p {
        font-size: 16px;
        line-height: 1.75;
        text-align: justify;
        color: $dark-gray;
        margin-bottom: 40px;
      }
    }
    img {
      margin-top: 50px;
    }
  }
  footer {
    height: 306px;
    width: 100%;
    background-color: $grey-1;
    display: flex;
    justify-content: center;
    align-items: center;
    >div {
      height: 120px;
      margin: auto;
      display: flex;
      flex-direction: column;
      svg {
        width: 40px;
        margin: 0 auto;
      }
      .clock-text {
        width: 532px;
        margin: 14px auto 0 auto;
        font-size: 14px;
        line-height: 1.86;
        text-align: center;
        color: #404040;
      }
    }
  }
}
