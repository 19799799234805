@import '../../../../src/variables.scss';

.Suc-modal:has(.local-json-dialog) {
    width: 50%;
    height: 60%;
}

.local-json-dialog {
    .Suc-dialog-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .copyButton {
            position: relative;
            bottom: 10px;
        }

        .configuration-area {
            position: relative;
            background-color: $very-light-gray;
            padding: 25px;
            width: 100%;
            min-height: 100%;
        }
    }
}