@import "../../variables";
@import "../../mixins";

$failed-color: $error;

#root .failed-files {
  width: $dashboard-width;
  margin: 0 auto;

  .bread-crumbs {
    display: flex;
    margin-bottom: 11px;
    margin-top:24px;
    font-size: 14px;
    color: $primary-blue;
    .back {
      &:hover {
        color: $primary-blue-hover;
        cursor: pointer;
      }
    }
    .right-arrow {
      height: 20px;
      svg {
        vertical-align: middle;
        width: 8px;
        height: 12px;
        margin-right: 11px;
        margin-left: 11px;
      }
    }
    .path {
      color: $dark-gray;
      @include nowrap();
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    height: 75px;
    box-shadow: $box-shadow-default;
    background-color: white;
    margin-bottom: 20px;
    padding: 10px 40px;
    .header-info {
      display: flex;
      .header-item.blue {
        .header-item-data {
          color: $primary-blue-hover;
        }

      }
    }
  }

  .relationship-info-container {
    display: flex;
    .endpoint-info {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .path-text {
        color: $darker-blue;
        max-width: 360px;
        @include nowrap();
      }
      .small-text {
        font-size: 12px;
        font-weight: 600;
        color: $darker-blue;
      }
    }
  }

  .summary-graphs {
    display: flex;
    justify-content: space-between;
    height: 280px;
    color: $primary-blue-hover;
    margin-bottom: 20px;
    gap: 20px;
  }

  .failed-files-table {
    .table-row {
      .value {
        font-weight: 600;
        color: $failed-color;
        font-size: 18px;
      }
    }
    .table-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
      h5 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .table-no-data {
      width: 100%;
      margin-top: 77px;
      color: #898989;
      text-align: center;
      line-height: 2;
    }
  }
}

//This button appears also in the relationship card
button.download-logs {
  color: $primary-blue;
  position: relative;
  .icon {
    position: absolute;
    top: -5px;
    left: -30px;
    svg {
      width: 24px;
      background-color: $white;
    }
  }
  &:hover {
    color: scale-color($primary-blue, $lightness: -20%);
    path {
      fill: scale-color($primary-blue, $lightness: -20%);
    }
  }
  &:disabled {
    color: $medium-gray;
    cursor: default;
    path {
      fill: $light-gray;
    }
  }
}

body.tab.cm-integration #root .failed-files-table table.standard {
  thead {
    background-color: $primary-blue-hover;
    font-size: 14px;
    td {
      vertical-align: middle;
      padding-bottom: 0;
    }
  }
  tr {
    border-radius: 2px;
    outline: thin solid rgba(215, 215, 215, 0.4);
  }
  tbody tr {
    background-color: $white;
  }
}

.Suc-tooltip-container.download-logs {
  max-width: 320px;
}
