@import "../../variables";
@import "../../mixins";

.data-broker-loading-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  .fade-in {
    animation: fade-in ease 0.5s forwards;
    opacity: 0;
  }
  svg.icon {
    width: 45px;
    margin-bottom: 30px;
  }
  .title {
    text-align: center;
    color: $primary-blue;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  .note {
    text-align: center;
    line-height: 1.86;
    width: 620px;
    animation-delay: 0.5s;
    margin-bottom: 20px;
  }
  .progress-bar {
    height: 8px;

    //to allow fade in of the progress bar:
    opacity: 0;
    &.progress-linear-animated:indeterminate {
      animation: progress-linear-animated 2s infinite linear, fade-in 2s ease 1s forwards;
    }
  }
}