@import "./../../variables";

.scrollarea .scrollbar-container.active, .scrollarea .scrollbar-container:hover {
  background: none;
  opacity: 1 !important;
}

.scrollarea .scrollbar-container{
  z-index: 400;
}

#root .scrollbar-container.vertical{
  .scrollbar{
    margin-left: 0;
  }

  &:before{
    content: "";
    height: 100%;
    position: absolute;
    width: 1px;
    display: block;
    border-right: 1px solid #9d9f9f;
    margin-left: 2px;
  }
}

.scrollarea.textarea, .scrollarea.popover-table{

  .scrollarea-content.content{
    padding-right: 20px;
  }

  &:before, &:after{
    content: "";
    width: calc(100% - 20px);
    display: block;
    z-index: 1;
  }

  &:before{
    border-top: 1px solid #d7d7d7;
    position: absolute;
    top: 0;
  }

  &:after{
    border-bottom: 1px solid #d7d7d7;
    position: absolute;
    bottom: 0;
  }
}


.scrollarea.popover-error{
  width: 100%;
  .scrollarea-content.content{
    padding-right: 25px;
    word-wrap: break-word;

  }
}


.scrollarea.textarea{
  font-size: 13px;

  .scrollarea-content.content{
    padding-bottom: 16px;
    padding-top: 16px;
  }

  &:before{
    background-color: $white;
    padding-bottom: 16px;
  }

  &:after{
    background-color: $white;
    padding-top: 16px;
  }
}


.scrollarea.main-scroll{
    .scrollbar-container.vertical{
    right: 5px;
    z-index: $main-scroll-z;
    &:before{
      height: 0;
      display: none !important;
    }
  }

  .scrollarea-content.content{
    min-height: 5px;
  }
}