@import "../../../variables";

.byol-licenses {

  .licenses-table {
    margin-bottom: 2%;

    &.adding {
      max-width: 89.5%;
      margin-bottom: 0;
      table:last-child {
        border-bottom: 0;
      }
    }
    table {
      table-layout: fixed;
      margin-bottom: 5px;

      tr {
        background-color: $very-light-gray;
      }

      th {
        padding: 15px 20px 2.5px 20px;
        &:first-child {
          width: 23%;
        }
        &:last-child {
          width: 48%;
        }
        text-align: left;
        font-size: 14px;
        font-weight: bolder;
      }

      td {
        padding: 2.5px 20px 15px 20px;

        &.expiry {
          &.warning {
            color: #deac43;
          }

          &.expired {
            color: #d0586c;
          }
        }
      }
    }
  }

  .under-table-button {
    padding-left: 4px;
    button {
      min-width: 150px;
    }
  }
}