@import "./../../../variables";
@import "./../../../mixins";

$card-padding: 40px;
.report-view {
  max-width: $dashboard-width;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  .bread-crumbs {
    display: flex;
    margin-bottom: 11px;
    font-size: 14px;
    color: $primary-blue;
    .back {
      &:hover {
        color: $primary-blue-hover;
        cursor: pointer;
      }
    }
    .right-arrow {
      height: 20px;
      svg {
        vertical-align: middle;
        width: 8px;
        height: 12px;
        margin-right: 11px;
        margin-left: 11px;
      }
    }
    .path {
      max-width: 600px;
      @include nowrap();
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    height: 75px;
    box-shadow: $box-shadow-default;
    background-color: white;
    margin-bottom: 20px;
    padding: 10px 40px;
    .header-info {
      display: flex;
      max-width: calc(100% - 390px);

      svg:not(.goto-error) {
        height: 56px;
        width: 56px;
        margin-right: 25px;
      }
      .header-item {
        padding-right: 12px;
        margin: 10px 20px 0 0;
        min-width: 140px;
        max-width: 280px;
        height: 40px;
        font-size: 14px;
        letter-spacing: 0.35px;
        &:not(:last-child) {
          border-right: 1px solid $grey-4-disabled;
        }

        &.header-item-path {
          flex: 1;
        }

        .header-item-title {
          @include nowrap();
          color: #a7a7a7;
        }
        .header-item-data {
          color: $dark-gray;
          a.errors {
            color: #DA1E27;
            top: -2px;
          }
          @include nowrap();
          .scan-duration {
            svg {
              width: 18px;
              height: 18px;
              vertical-align: middle;
              margin-right: 11px;
            }
          }

          .report-status {
            display: flex;
            .report-status-text {
              font-size: 14px;
              color: $grey-7-primary-text;
            }
            svg {
              width: 18px;
              height: 18px;
              vertical-align: middle;
              margin-right: 11px;
            }
            .info .Suc-tooltip-info-container {
              svg {
                fill: $error-color;
                margin-right: 10px;
                top: 2px;
                position: relative;
              }
            }
          }
        }
      }
    }
    .select-container {
      display: flex;
      gap: 26px;
      .Suc-Select-container {
        width: 330px;
        margin-top: 10px;
      }
      .Suc-button.Suc-secondary.delete-report-button {
        margin-top: 14px;
        background-color: white;
        border-radius: 16px;
        min-width: 128px;
        height: 32px;
        &:hover {
          background-color: white;
        }
      }
    }
    .menu-popover-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 10px;
    }

  }
  .report-card {
    padding: 26px $card-padding;
    .card-header {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      color: $primary-blue;
      border-bottom: solid 1px $grey-4-disabled;
      display: flex;
      justify-content: space-between;
    }
    .card-content {
      padding-top: 24px;
      height: 100%;
    }
    .summary-item {
      display: flex;
      flex-direction: column;
      width: 200px;
      .summary-top {
        display: flex;
        svg {
          width: 50px;
          height: 50px;
          margin: auto 23px auto 0;
          position: relative;
          top: 10px;
        }
        .summary-values {
          display: flex;
          flex-direction: column;
          .summary-count {
            font-size: 32px;
            letter-spacing: 0.8px;
            color: $grey-7-primary-text;
            @include nowrap();
            max-width: 180px;
          }
          .summary-title {
            font-size: 14px;
            letter-spacing: 0.35px;
            color: #a7a7a7;
          }
        }
      }
      .summary-bottom {
        display: flex;
        justify-content: space-between;
        border-top: solid 1px $grey-4-disabled;
        height: 35px;
        font-size: 14px;
        letter-spacing: 0.35px;
        padding-top: 10px;
        margin-top: 25px;
        .summary-title {
          color: #a7a7a7;
        }
        .summary-count {
          color: $grey-7-primary-text;
        }
      }
    }
  }
  .summary-item {
    display: flex;
    flex-direction: column;
    width: 200px;
    .summary-top {
      display: flex;
      svg {
        width: 50px;
        height: 50px;
        margin: auto 23px auto 0;
      }
      .summary-values {
        display: flex;
        flex-direction: column;
        .summary-count {
          font-size: 32px;
          letter-spacing: 0.8px;
          color: $grey-7-primary-text;
        }
        .summary-title {
          display: flex;
          font-size: 14px;
          letter-spacing: 0.35px;
          color: #a7a7a7;
          .summary-title-icon {
            width: 10px;
            height: 10px;
            margin: auto 10px auto 0;
          }
        }
      }
    }
    .summary-bottom {
      display: flex;
      justify-content: space-between;
      border-top: solid 1px $grey-4-disabled;
      height: 35px;
      font-size: 14px;
      letter-spacing: 0.35px;
      padding-top: 10px;
      margin-top: 25px;
      .summary-title {
        color: #a7a7a7;
      }
      .summary-count {
        color: $grey-7-primary-text;
      }
    }
  }

  .summary {
    height: 320px;
    box-shadow: $box-shadow-default;
    background-color: white;
    margin-bottom: 20px;
    .summary-content {
      display: flex;
      justify-content: space-between;
      .graph {
        width: 240px;
        height: 260px;
        padding-top: 40px;
        position: relative;
        canvas {
          position: absolute;
          top: 15px;
          left: 3px;
          height: 200px !important;
          width: 200px !important;
          z-index: 100;
        }
        .graph-info {
          position: absolute;
          top: 80px;
          left: 33px;
          width: 140px;
          color: $grey-7-primary-text;
          .graph-info-title {
            font-size: 18px;
            text-align: center;
          }
          .graph-info-count {
            font-size: 37px;
            text-align: center;
            @include nowrap();
          }
        }
      }
      .summary-numbers {
        width: 960px;
        padding-top: 80px;
        display: flex;
        gap: 30px;
        &.box {
          justify-content: flex-start;
          gap: 40px;
        }
      }
    }
  }

  .reports-view-objects {
    .top-line {
      display: flex;
      justify-content: space-between;
      .summary-graph {
        width: 400px;
        height: 380px;
        box-shadow: $box-shadow-default;
        background-color: white;
        margin-bottom: 20px;
        position: relative;
        .summary-content {
          height: 200px;
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          gap: 48px;
          .summary-item {
            width: 250px;
            svg {
              height: 50px;
              width: 50px;
              fill: #8345e6;
            }
            .summary-count {
              max-width: 180px;
            }
          }
        }
      }
    }
  }
  .files {
    height: 380px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .files-sizes {
      width: 920px;
      &.objects-view {
        width: 860px;
      }
      box-shadow: $box-shadow-default;
      background-color: white;
      canvas {
        width: 840px !important;
        height: 279px !important;
      }
      &.objects-view {
        canvas {
          width: 780px !important;
          height: 279px !important;
        }
      }
    }
    .files-maximums {
      width: 340px;
      padding: 24px 40px 61px;
      box-shadow: $box-shadow-default;
      background-color: white;
      .maximums-header {
        display: flex;
        justify-content: space-between;
      }
      .Suc-tooltip-info-container.maximums-info {
        vertical-align: middle;
      }
      .maximums-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .maximum-part {
          height: 129px;
          border-bottom:  solid 1px $grey-4-disabled;
          padding: 30px 0;
        }
        svg {
          width: 50px;
          height: 50px;
        }

      }
    }
  }
  .times {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .times-box {
      width: 414px;
      height: 400px;
      &.wide {
        width: 49%;
      }
      &.objects-view {
        width: 630px;
      }
      &.multiple-columns-2 {
        height: 300px;
        width: 630px;
      }
      &.multiple-columns-3 {
        width: 100%;
        height: 235px;
      }
      padding: 24px 39px 34px 40px;
      box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
      background-color: white;
    }
  }
}
