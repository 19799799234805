@import "~foundation-sites/scss/util/util";
@import "../variables";

#root {
  .oops {
    .logo {
      padding: 10px;
      width: 140px;
      position: absolute;
      left: 110px;
      top: 27px;
    }

    width: 600px;
    height: 300px;
    text-align: center;
    margin: 10% auto;

    .support {
      margin-top: 30px;
    }

    svg {
      width: 100px;
      height: 100px;
      fill: $darker-blue;
    }

    button {
      margin-top: 40px;
    }
  }
}
