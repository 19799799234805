@import "./../../variables";

.table-loader {
  position: relative;
  width: 100%;
  height: 54px;
  text-align: center;
  line-height: 54px;
  background-color: $white;
}

body.tab #root .table-loader {
  height: 60px;
  line-height: 60px;
}

body.tab.darkula #root table.standard thead {
  background-color: $darker-blue;
  color: $secondary-color;
}

body.tab.cm-integration #root table.standard thead {
  background-color: #4ca0ff;
}

body.tab #root table.standard {
  $row-height: 60px;
  table-layout: fixed;

  thead {
    background-color: $table-header-color;
  }

  tr {
    height: $row-height;
    background-color: transparent;
    &:hover {
      box-shadow: 2px 8px 6px -6px rgba(187, 187, 187, 0.5);
    }
  }

  td:not(.loader):not(.table-menu):not(.remaining-time):not(.with-tooltip):not(.filter-header) {
    padding-right: 15px;
    @media screen and (max-width: $small-resolution) {
      padding-right: 5px;
    }
    @include nowrap();
  }

  .tr-exit, .tr-enter {
    .table-menu * {
      display: none;
    }

    button, svg {
      display: none;
    }
  }

  .tr-enter-active {
    height: $row-height;
  }

  .tr-exit {
    height: $row-height;
  }

  .tr-exit-active {
    height: 0;
    line-height: 0;
  }
}

#root table.standard {
  $row-height: 54px;
  overflow: auto; //in order to allow the filter popover to be shown beyond the table border

  thead {
    td {
      &.filter-header {
        position: relative;
        overflow: visible;
      }

      button {
        &.sort-button, &.filter-button {
          float: right;
          position: relative;
          top: 3px;
        }

        &.sort-button {
          svg {
            width: 16px;
            height: 13px;
          }
        }
        &.filter-button {
          svg {
            width: 15px;
            height: 10px;
          }
        }

        &.filter-button.active svg path {
          fill: $primary-blue-hover;
        }

      }
    }

    svg {
      margin-right: 11px;
      fill: $white;
    }

    .darkula & {
      background-color: $darker-blue;
      color: $secondary-color;
    }

  }
  
  .checkCell {
    padding-left: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    width: 55px;
    
    .Suc-checkbox-container {
      height: 16px;
      display: flex;
    }
  }

  .blank {
    width: 37px;
  }

  .check, .checkmark {
    width: 113px;
  }

  tr:not(.selected) .check:not(.plus-button) {
    > * {
      display: none;
    }
  }

  tr:not(.selected) .checkmark:not(.plus-button) {
    > * {
      display: none;
    }
  }

  &.menuable {
    tbody tr {
      transition: background-color $default-transition-timing;
      .table-menu {
        &.disabled * {
          display: none;
        }
        .Suc-popover-trigger-container.Suc-menu-trigger {
          position: relative;
          top: 3px;
        }
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }

  &.selectable {
    tbody tr {
      cursor: pointer;

      &:hover {
        background-color: $item-hover;
      }

      &.selected {
        td {
          color: $primary-blue;
          fill: $primary-blue;

          &:not(.additional) {
            font-weight: bolder;
          }
        }
      }

    }
  }

  tr {
    height: $row-height;
  }

  thead {
    td {
      font-weight: normal;
    }

    font-size: 15px;
  }


  tbody {
    tr {
      border-top: 1px solid $wizard-background;

      .darkula & {
        background-color: $dark-blue-lighter;
      }
    }


    .tr-enter {
      height: 0;
      opacity: 0;
      line-height: 0;
      background-color: #f1f1f1;

      td {
        overflow: hidden
      }
    }

    .tr-enter-active {
      height: $row-height;
      opacity: 1;
      background-color: $white;
      transition: all 0.4s linear !important;
    }

    .tr-exit {
      height: $row-height;

      td {
        overflow: hidden
      }
    }

    .tr-exit-active {
      height: 0;
      line-height: 0;
      transition: height 0.4s linear !important;
    }


    td {
      font-size: 14px;

      .darkula & {
        color: #c4ced8;
      }

      &.check, &.checkmark {
        padding-left: 20px;

        svg {
          width: 30px;
        }
      }

      &.failed {
        color: $error;
      }

      span.capacity-unit {
        float: right;
        font-size: 14px;
        display: block;
        width: 32px;
        height: 22px;
        margin-right: 16px;
        text-align: center;
        line-height: 22px;
        color: $white;
        margin-left: 10px;
        margin-top: 8px;

        &.TB {
          background-color: #a1a1a1;
        }

        &.GB {
          background-color: #c2c2c2;
        }

        &.MB {
          background-color: #d3d3d3;
        }

        &.KB {
          background-color: #e3e3e3;
        }
      }

      &.additional {
        font-size: 25px;
        font-weight: lighter;
        color: #707070;
      }
    }
  }
}

