@import "../../variables";
@import "./../../mixins";

.system-flow {
  .full-flow-diagram {
    svg {
      display: block;
      width: 425px;
      height: 157px;
      position: relative;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .divTable {
    width: 625px;
    margin: 0 auto 35px auto;
  }
  .divTableRow {
    display: flex;
    justify-content: space-between;
    &.bi-directional {
      margin: 0 40px;
    }
    &.bottom {
      justify-content: center;
      margin-top: 10px;
    }
  }

  .divTableCellBig {
    padding-top: 6px;
    svg {
      height: 92px;
      fill: none;
    }
    &.dotted svg {
      height: 96px;
      fill: none;
    }
  }

  .table-cell-container {
    width: 229px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //to compensate on the arrows svg not being centered
    &:last-of-type {
      margin-right: 6px;
    }
    svg {
      width: 70px;
    }
    .title {
      color: $primary-blue;
      font-weight: 700;
    }
    &.arrows {
      svg {
        width: 76px;
      }
    }
  }

  p {
    font-size: 14px;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    line-height: 1.7;
  }
}

.system-flow.encrypted {
  .encryption-text {
    margin-top: 40px;
    text-align: center;
  }
}

.flow-encrypted {
  width: 824px;
  position: relative;
  margin: 0 auto;
  .end-point {
    position: absolute;
    top: 216px;
    width: 200px;
    height: 120px;
    z-index: 400;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    .end-point-title {
      font-weight: 600;
      color: $primary-blue;
    }
    svg {
      width: 81px;
    }

    &.source {
      left: 17px;
    }
    &.target {
      left: 645px;
    }
  }

  .diagram {
    margin-right: auto;
    margin-left: auto;
    margin-top: 22px;
    z-index: 0; //for relationship step
  }
}
