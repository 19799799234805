@import "../../../variables";

.aclMultiSelection {
    width: 400px;

    .Suc-Select__control {
        .Suc-Select__value-container {
            .Suc-Select__placeholder {
                color: $dark-gray !important;
            }
        }
    }
}