@import "../../variables";

.provider-login-layout {
  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      a {
        margin-left: 3px;
      }
      .Suc-tooltip-info-container {
        margin-left: 5px;
      }
    }
    .aws-login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px auto;
      width: 360px;
    }
  }
}