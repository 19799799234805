@import "../../../variables";

.license-settings {
  display: flex;
  flex-direction: column;
  width: 1075px;
  margin: 0 auto;
  .subscription-card {
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    line-height: 1.7;
    margin-bottom: 15px;
  }

  @media screen and ( max-width: 1200px ){
    margin-left: 0 !important;
  }

  .header {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: normal;
    &.hi {
      font-size: 32px;
    }
  }

  h5 {
    font-size: 18px;
    font-weight: bolder;
  }

  .button {
    width: 150px;
  }

  .row {
    max-width: none;
    margin-left: 0;
  }

  .payment-option {
    &.license-info {
      padding: 10px 0;
    }

    .payment-icon {
      width: 100px;

      svg {
        width: 77px;
        height: 77px;
      }
    }

    .payment-text {
      margin: 0 5% 0 0;
    }

    .payment-action {
      button {
        margin-top: 50%;
        min-width: 150px;
      }

      img {
        margin: 70% 0 0 100%;
        height: 7px;
        width: 50px;
      }
    }

  }

  .marketplace-subscription {
    margin-top: 20px;
    p {
      color: $primary-blue;
      font-size: 16px;
      font-weight: 500;
    }
    .radio {
      display: flex;
      margin-bottom: 20px;
      label {
        font-size: 14px;
        color: $primary-blue;
        font-weight: 600;
        margin-right: 40px;
      }
    }
  }

  .licenses-capacity-warning {
    $license-warning-color: #e29d09;
    color: $license-warning-color;
    display: flex;
    flex-direction: row;
    svg {
      position: relative;
      top: 2px;
      margin: 0 10px;
      width: 16px;
      height: 16px;
      fill: $license-warning-color;
    }
    border-bottom-color: $license-warning-color;
  }

  .not-now {
    margin: 15px auto 20px auto;
  }

}