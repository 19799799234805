@import "../../../variables";

.current-license-section {
  min-width: 700px;
  margin-top: 10px;
  margin-left: 0;
  padding: 15px;
  background-color: $white;
  color: $grey-7-primary-text;
  box-shadow: $box-shadow-default;
  font-weight: lighter;
  font-size: 30px;

  .relationship-count {
    margin-right: 20px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: bolder;

    .count {
      font-size: 24px;
      font-weight: lighter;
      line-height: normal;
    }

    &.warning {
      color: #fcc858;
    }
  }
}