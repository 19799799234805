@import "../../../variables";

.multi-input {
  box-sizing: border-box;
  border: 1px solid $grey_5;
  border-radius: 4px;
  box-shadow: none;

  .multi-input__control:hover {
    border-color: $grey_5;
    border-width: 2px;
  }
  .multi-input__control.multi-input__control--is-focused {
    border-color: $primary-blue;
    border-width: 2px;
  }

  .multi-input__placeholder {
    font-size: 13px;
    font-style: italic;
  }
}