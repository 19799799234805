@import "./../../variables";

$li-width: 100px;

header.primary nav.nav-tabs{
  position: absolute;
  height: 100%;
  top: 0;
  left: 333px;
  font-size: 18px;

  ul{
    display: flex;
    gap: 32px;
    margin: 0;
    height: 100%;
    li{
      list-style-type: none;
      padding-top: 24px;
      height: 100%;
      width: $li-width;
      text-align: center;
      position: relative;
      &.wide {
        width: 182px;
      }

      .active-block {
        width: 100%;
        transform: scale(0, 1);
        height: 10px;
        position: absolute;
        bottom: 0;
        background-color: $darker-blue;
        transition: transform 0.2s linear, background-color $default-transition-timing;
        .darkula &{  background-color: $secondary-color  }
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        color: $white;
        opacity: 0.8;
        transition: opacity $default-transition-timing, color $default-transition-timing;
        &.is-active{
          color: $darker-blue;
          opacity: 1;
          .active-block {
            transform: scale(1, 1);
          }
          .darkula & {  color: $secondary-color  }
        }

        &:hover{
          opacity: 1;
        }

      }
    }
  }
}
