@import "../../../variables";

.data-broker-function {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description {
    text-align: center;
    margin-top: 20px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    position: relative; //needed for the buttons to be on top of the svg
    bottom: 23px;
    .button-wrapper {
      margin-right: auto;
      margin-left: auto;
      svg.dots {
        width: 50px;
        &.translated {
          transform: rotateY(180deg);
        }
      }
      .switch-initiator {
        display: inline-block;
        width: 170px;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .databrokers-function-pair {
      margin: 0 auto 7px;
      .databroker-function {
        width: 104px;
        height: 24px;
        border-radius: 40px;
        border: solid 1px $accent-blue-1;
        background-color: $secondary-hover-fill;
        color: $accent-blue-1;
        font-size: 11px;
        font-weight: bold;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &.target {
          margin-left: 153px;
        }
      }
    }
  }
}
