@import "../../../variables";
@import "../../../mixins";

#root .relationship-step {
  .review-data-broker-card {
    box-shadow: 0 2px 2px 0 rgba(187, 187, 187, 0.5);
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 110px;
    display: flex;
    align-items: center;
    background-color: $white;
    .data-broker-icon {
      max-width: 75px;
      svg {
        width: 75px;
        margin-right: 25px;
      }
    }
    .broker-details {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 14px;
        font-weight: bolder;
        margin-bottom: 6px;
        line-height: 16px;
        color: $primary-blue;
      }
      .data-brokers-info {
        display: flex;
        justify-content: flex-start;
        .double-line {
          padding: 0 16px;
          &:first-child {
            padding-left: 0;
          }
          .double-line-value {
            max-width: 220px;
          }
        }
      }
    }
  }
}
