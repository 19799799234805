@import "../../../variables";

.change-server {
  font-weight: normal;
  display: flex;
  text-decoration: underline;
  svg {
    fill: $primary-blue;
    width: 12px;
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
    svg {
      fill: $primary-blue-hover;
    }
  }
}
