@import "./../../../variables";


.Suc-dialog .Suc-modal .Suc-default-dialog-layout.edit-proxy-dialog {
  width: 600px;
  b {
    display: block;
    margin-bottom: 5px;
    span {
      color: $warning-color;
    }
  }
}
