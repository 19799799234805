@import "../../../variables";

.contact-us {
  margin-top: 15px;

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;

    fill: $primary-blue;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }

  p {
    text-align: center;
  }

}