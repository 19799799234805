@import "../../variables";
@import "../../mixins";


$move-data-broker-dialog-height: 554px;
$move-data-broker-dialog-width: 866px;
.Suc-dialog .Suc-modal .Suc-default-dialog-layout.move-data-broker-dialog {
  width: 866px;
  max-height: $move-data-broker-dialog-height;
  .Suc-dialog-content {
    padding: 0;
  }
}

.move-data-broker-groups {
  display: flex;
  .pane {
    padding: 20px 0;
    &.left {
      display: flex;
      flex-direction: column;
      width: calc(#{$move-data-broker-dialog-width - 550px});
      padding: 40px 20px;
      gap: 20px;
      .title {
        font-weight: bold;
        color: $dark-blue;
      }
    }
    &.right {
      width: 550px;
      background-color: #F5F4F6;
    }
    .groups-list.widgets-cards-container {
      min-height: 230px;
      .card-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .selectable {
          &.selected .move-data-broker-group-card{
            border: solid 1px $primary-blue;
            background: linear-gradient(0deg, rgba(25, 181, 254, 0.05), rgba(25, 181, 254, 0.05)), #FFFFFF;
          }
        }
      }
      .widgets-container {
        width: 467px ;
        margin: 0 auto 10px auto;
      }

    }
    .new-group {
      margin-bottom: 40px;
      &.selected {
        .move-data-broker-group-card {
          border: solid 1px $primary-blue;
          background: linear-gradient(0deg, rgba(25, 181, 254, 0.05), rgba(25, 181, 254, 0.05)), #FFFFFF;
        }
      }
    }
    .move-data-broker-group-card {
      margin: 0 auto;
      display: flex;
      width: 467px;
      height: 74px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      background-color: $white;
      &.new-group-card {
        padding: 15px 0;
        height: 100%;
        &.edit {
          gap: 33px;
        }
      }

      .broker-details {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Suc-text-input {
          &.Suc-bottom-margin {
            margin-bottom: 0;
          }
          .Suc-input-top .Suc-input-label {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.38;
            color: #404040;
          }
        }
        .double-line .double-line-title {
          font-size: 14px;
          color: $primary-blue;
        }
        .new-data-broker-group-container {
          display: flex;
          .double-line {
            border-right: none;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .group-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        svg {
          margin-left: 20px;
        }
      }
    }
  }
}
