@import "../../../variables";
@import "../../../mixins";

.Suc-default-dialog-layout.subscription-flow-dialog {
  width: 675px;

  h6 {
    font-weight: bold;
    color: $primary-blue;
  }

  .step {
    height: 80px;

    .step-icon {
      img, svg {
        height: 75px;
        width: 75px;
        fill: $primary-blue;
      }
    }
  }

  .step-divider {
    height: 35px;
    margin: 15% 40%;
    border-left: dotted 2px $border-color;
  }

  .view-video-container {
    position: absolute;
    height: 64px;
    left: 30px;
    bottom: 0;
    .view-video {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }
  }
}