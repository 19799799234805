@import "./../../variables";

.Suc-dialog {
  .delete-relationship-dialog {
    .Suc-header {
      z-index: 1;
    }

    .Suc-dialog-content {
      max-width: 1000px;
      overflow-wrap: anywhere;

      .multiRelationsDelete {
        .relationshipCell {
          display: flex;
          flex-direction: row;
          align-items: center;

          .icon {
            width: 40px;
            display: flex;
            margin-right: 20px;
          }

          .cellText {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}