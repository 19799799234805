@import "../../../mixins";
@import "../../../variables";

#small-loader {
  width: 25px;
  height: 25px;
  display: inline-block;
}

#small-loader .primary-stroke {
  stroke: $primary-blue;
}

#small-loader svg {
  -webkit-animation: cc-rotate-animation 2s linear infinite;
  animation: cc-rotate-animation 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 25px;
}

#small-loader circle{
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: cc-rotate-dash-animation 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: cc-rotate-dash-animation 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}