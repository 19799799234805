@import "../../variables";

.refresh-button {
  display: flex;
  align-items: center;
  svg {
    width: 23px;
    path {
      fill: $primary-blue;
      stroke: $primary-blue;
    }
  }
  &:hover:not(.disabled) {
    color: $primary-blue-hover;
    svg path {
      fill: $primary-blue-hover;
      stroke: $primary-blue-hover;
    }
  }

  &.disabled {
    opacity: 0.5;
    user-select: none;
  }
}