@import "../../variables";
$provider-button-height: 140px;
$provider-button-width: 190px;

.provider-type.button {
  height: $provider-button-height;
  width: $provider-button-width;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  position: relative;
  &.disabled, &.button:hover {//This is needed to override the "body.tab .button" class definition from globals
    background-color: $white;
    opacity: 1;
  }
  .provider-type-content {
    display: flex;
    flex-direction: column;
    height: $provider-button-height;
    padding: 20px 0;
    .provider-type-icon {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 55px;
      }
      &.disabled svg{
        fill: $grey-5;
      }
    }
    .provider-type-text {
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #434957;
    }
  }


  &:not(.disabled):hover {
    box-shadow: 4px 6px 12px 0 rgba(187, 187, 187, 0.5);
  }
  .checkmark {
    display: none;
    position: absolute;
    top: -14px;
    right: -9px;
    svg {
      width: 30px;
    }
  }
  &.selected {
    border: 1px solid $primary-blue;
    .checkmark {
      display: inline-block;
    }
  }
}