@use "sass:math";
@import "./variables";

@mixin nowrap($width: null) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}

@mixin wizard-step-animation-up() {
  animation: float-up $default-transition-timing;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float-up {
  0% {
    margin-top: 100%;
  }
  100% {
    margin-top: 0;
  }
}

@mixin wizard-step-animation-down() {
  animation: float-down $default-transition-timing;
}

@keyframes float-down {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 0;
  }
}

@mixin default-scrollbar($width: 13px) {
  $border-size: math.div($width - 3, 2);

  &::-webkit-scrollbar {
    background-color: transparent;
    width: $width;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px 20px 20px 10px / 15px;
    border-radius: 10px 20px 20px 10px / 15px;
    background-color: $light-gray;
    border-right: $border-size solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin scale-up-animation() {
  -webkit-animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 4 both;
  animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 4 both;
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@mixin button-style-cs(
  $bg: $button-primary-bg,
  $hover-bg: $button-primary-hover-bg,
  $disabled-bg: $button-primary-disabled-bg
) {
  @include button-style($bg, $hover-bg, auto);
  border-radius: 2px;
  border: 1px solid transparent; //to be the same size as the secondary that has border
  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    background-color: $disabled-bg;
  }
}

@mixin button-style-border(
  $text: $button-secondary-text,
  $bg: $button-secondary-bg,
  $hover-text: $button-secondary-hover-text,
  $hover-bg: $button-secondary-hover-bg,
  $disabled-text: $button-secondary-disabled-text,
  $disabled-bg: $button-secondary-bg
) {
  @include button-style($bg, $hover-bg, auto);
  border-radius: 2px;
  color: $text !important; //to override foundation
  border: 1px solid $text;
  &:hover, &:focus {
    color: $hover-text !important; //to override foundation;
    border: 1px solid $hover-text;
  }

  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    background-color: $disabled-bg;
    color: $disabled-text !important; //to override foundation;;
    border: 1px solid $disabled-text;
  }
}

@mixin dialog($width: 845px, $height: 510px, $important: null) {
  .modal {
    width: $width $important;
    height: $height $important;
    left: calc(50% - #{$width/2}) $important;
    top: calc(50% - #{$height/2}) $important;
  }
}

@mixin popover-icon-color($enabledColor:#b7b7b7, $disabledColor: $light-gray) {
  .Suc-popover-trigger-container {
    svg {
      fill: $disabledColor;
    }

    &:hover, &.active {
      svg {
        fill: $enabledColor;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@mixin spin() {
  animation: spin 2s infinite linear;
}

@mixin circledBorder($width: 26px, $color: #959595) {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid $color;
  width: $width;
  height: $width;
}

@mixin disabledField() {
  border-color: $disabled-input-border;
  background-color: $disabled-input-bg;
  color: $disabled-text;
}

@-webkit-keyframes cc-rotate-dash-animation {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes cc-rotate-dash-animation {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes cc-rotate-animation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cc-rotate-animation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin sync-status(){
  @include nowrap();
  max-width: 200px;
  width: 200px;
  .Suc-popover-trigger-container .failed-trigger svg {
    width: 14px;
  }
  span.cycel-icon{
    margin-left: 15px;

    @include nowrap();
  }
  span.small-text{
    font-size: $accent-blue-1;
    margin-left: 45px;
  }
  &.syncing{
    color: $accent-blue-1;
    .status-txt {
      svg {
        fill: $accent-blue-1;
      }
    }
    @include popover-icon-color($accent-blue-1);

    button.info:hover svg, button.info .active svg{
      fill: $accent-blue-1;
    }
  }
  &.unknown {
    color: $medium-gray;
    .status-txt {
      svg {
        fill: $medium-gray;
      }
    }
    @include popover-icon-color($medium-gray);
  }
  &.synced-done{
    color: $success;
    .status-txt {
      svg {
        fill: $success;
      }
    }
    @include popover-icon-color($success);
    button.info:hover svg, button.info .active svg{
      fill: $success;
    }
  }
  &.failed{
    color: $error !important;
    .status-txt {
      svg {
        fill: $error;
      }
    }
    @include popover-icon-color($error);
  }
  &.pending {
    color: #636363;
    @include popover-icon-color($primary-blue-hover);
  }
  .sync-icon{
    margin-right: 10px;
    width:25px;
    height:25px;
  }
}

@mixin slide-in-bottom(){
  -webkit-animation: slide-in-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@mixin roll-down(){
  -webkit-animation: roll-down 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  animation: roll-down 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-down
 * ----------------------------------------
 */
@-webkit-keyframes roll-down {
  0% {
    max-height: 0;
  }
  100% {
    max-height: $notification-height;
  }
}
@keyframes roll-down {
  0% {
    max-height: 0;
  }
  100% {
    max-height: $notification-height;
  }
}
