@import "../../variables";
@import "../../mixins";

#root .breadcrumb-scroll{
  .scrollbar{
    background: $primary-blue !important;
    height: 5px !important;
    margin-top: 0;
  }

  .scrollbar-container{
    opacity: 1 !important;
    bottom: 3px !important;
    height: 5px !important;
  }
}

#root .drilldown-breadcrumbs{
  width: 910px;
  margin: 20px auto;

  .folder-empty{
    color: #c1c1c1;
    margin-top: 70px;
    text-align: center;

    svg{
      width: 48px;
      height: 39px;
      fill: #c1c1c1;
    }
  }

  .search-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-count {
      display: flex;
      svg.icon {
        width: 48px;
        height: 39px;
      }
      .item-count-widget{
        margin-left: 17px;

        .count{
          font-size: 35px;
          line-height: 43px;
          color: $primary-blue;
        }
      }

      .loadingDots {
        display: flex;
        align-items: center;

        .dotsLoader {
          margin-right: 10px;
        }
      }
    }
    .search-and-refresh {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .search-widget{
        top: 4px;
      }
      .refresh-button-container {
        position: relative;
        top: 2px;
        padding-left: 9px;
        margin-left: 5px;
        margin-right: 2px;
        border-left: 1px solid $light-gray;
      }
    }
  }

  table{
    table-layout: fixed;
    margin-bottom: 0;
  }

  table.standard tbody{
    td.additional{
      width: 120px;
    }

    td.path{
      padding-left: 10px;
      @include nowrap();

      .text{
        @include nowrap();
      }
    }

    td.additional{
      font-weight: inherit;
    }

    td{
      .count{
        font-size: 25px;
        font-weight: lighter;
        margin-top: 1px;
        margin-bottom: -6px;
      }

      .text{
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 5px;
      }

      .title{
        font-size: 14px;
        margin-top: 3px;
        font-weight: bolder;
      }
    }

    tr{
      border-top-width: 2px;
      height: 74px;
    }

    .dive{
      width: 78px;
      height: 74px;
      button{
        width: 100%;
        height: 100%;
        display: none;
      }

      svg{
        width: 18px;
        height: 33px;
        margin-top: 10px;
      }
    }

    tr:hover{
      .dive button{
        display: block;
      }
    }

    tr:hover{
      .dive{
        border-left: 1px solid #d7d7d7;

        button:hover{
          svg{
            fill: #cdebfa;
          }

          background-color: $primary-blue;
        }

        svg{
          fill: #a6a6a6;
        }
      }
    }


    tr:hover .check {
      > * {
        display: block;
      }
    }

    tr:hover:not(.selected) .check:not(:hover),td.plus-button{
      svg{
        fill: #ccc;
      }
    }

    td.check{
      width: 76px;
      padding-left: 24px;
      padding-top: 9px;

      &:hover{
        svg{
          fill: $primary-blue;
        }
      }

      svg{
        width: 32px;
        height: 32px;
      }
    }
  }

  .breadbox{
    white-space: nowrap;
    display: inline-block;
    border-bottom: 1px solid $primary-blue;
    min-width: 100%;
    padding-right: 5px;
  }

  .breadcrumbs-row{
    height: 49px;
    border-top: 1px solid $primary-blue;
    line-height: 42px;
    margin-bottom: 5px;

    .chevron svg{
      fill: $dark-gray;
      width: 8px;
      height: 14px;
    }

    .chevron{
      margin-left: 21px;
      margin-right: 20px;
    }

    .count{
      font-size: 20px;
      font-weight: lighter;
    }

    .path button:disabled{
      cursor: default;
    }

    .path .check{
      svg{
        width: 25px;
        height: 25px;
        position: relative;
        top: 6px;
        margin-right: 8px;
      }
    }

    .path .check{
      display: none;
    }
  }

  &:not(.has-selection){
    .breadcrumbs-row{
      .path:last-child {
        .name{
          color: $primary-blue;
        }

        .check{
          display: inline;
        }
      }
    }
  }
}