@import "./../../variables";
@import "./../../mixins";

.recommendations-message {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.reverse {
    flex-direction: row-reverse;
  }
  @include popover-icon-color($primary-blue-hover);

  .recommendation-trigger {
    padding: 5px;
    display: flex;
    align-items: center;

    .i-icon {
      position: static;
      fill: $light-gray;
      width: 15px;
      @include scale-up-animation();
    }
  }
  .recommendations-text{
    padding-left: 5px;
    color: $darker-blue;
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.Suc-tooltip-container.recommendations-tooltip {
  max-width: 710px;
  background-color: $tooltip-background-color;
  color: $tooltip-text-color;
  border-radius: 2px;
  padding: 10px;
  font-size: 14px;
  box-shadow: $box-shadow-default;

  .recommendations-list {
    list-style-image: url('../../assets/svgs/arrow-right-pointer.svg');

    li {
      margin-right: 10px;
      padding: 5px;

      &:not(:last-child) {
        border-bottom: 1px solid $light-gray
      }
    }
  }
}
