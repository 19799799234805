@import "./../../variables";

.wizard-header.broker-instance-steps {
  background-color: #f8f8f8;
  height: 75px;
  border-bottom: solid 1px $grey-3;
  box-shadow: none;
  margin-top: 4px;

  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}