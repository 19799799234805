@import '../../variables';

.installation-instructions {
  $content-width: 776px;
  width: $content-width;
  .section {
    align-self: flex-start;
    margin-bottom: 30px;
  }

  .section-access {
    .enable-access-header {
      margin-bottom: 10px;
    }

    label {
      display: inline-block;
      margin-right: 40px;
    }

    .note {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .section-proxy {
    .proxy-options {
      width: 350px;

      .Select__indicator {
        position: relative;
        top: 2px;
      }
    }
  }

  .section-commands {
    .command {
      width: $content-width;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;

      .command-text {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        pre {
          width: $content-width;
          font-weight: bold;
          padding: 7px 40px 7px 7px;
          background-color: #d7d7d7;
          text-overflow: ellipsis;
          overflow-x: hidden;
          border-radius: 4px;
        }
      }

      svg {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
}