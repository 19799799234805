@import "./../../../variables";

.report-error-status {
  display: flex;
  .red-circle {
    border-radius: 50%;
    background-color: $alert-color;
    width: 8px;
    height: 8px;
    margin: auto 6px auto 0;
  }
  .report-error-status-text {
    color: $dark-gray;
    margin-right: 6px;
  }
  .report-error-link {
    svg.goto-error {
      width: 8px;
      height: 8px;
      fill: $dark-gray;
      &:hover {
        cursor: pointer;
        fill: $primary-blue;
      }
    }
  }
}
