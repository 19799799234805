@import "../../variables";

.cm-header {
  background-color: $white;
  height: 100%;
  $side-elements-width: 270px;
  $lap-top-breakpoint: 1800px;
  .header-content {
    display: flex;
    align-items: center;
    height: $primary-header-height;
    margin: 0 auto;
    width: 100%;
    position: relative;
    .title {
      padding-left: 40px;
      color: $primary-blue;
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height:20px;
      svg {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        fill: $primary-blue;
      }
    }
    .middle {
      flex: 1;
      text-align: center;
      height: 100%;
      .header-name {
        color: $primary-blue;
        font-weight: 300;
        font-size: 24px;
        padding-top: 10px;
        padding-right:26px;
      }
    }
    .goto-link {
      display: flex;
      align-items: center;
      color: $primary-blue;
      svg {
        width: 16px;
        margin-left: 5px;
      }
    }
    .close {
      text-align: right;
      display: flex;
      align-items: center;
      padding-right: 25px;
      svg {
        width: 30px;
        fill: #d7d7d7;
      }
    }
  }

  &.dashboard {
    .content {
      justify-content: space-between;
      margin: auto;
      width: 100%;
      .title {
        padding-left: 0;
        svg {
          margin-left: 104px;
          @media screen and ( max-width: $lap-top-breakpoint ){
            margin-left: 30px;
          }
        }
      }
      .middle {
        height: 100%;
        flex: 0 0 $dashboard-width;
        @media screen and ( max-width: $lap-top-breakpoint ){
          flex: 1;
        }
      }
      .close {
        visibility: hidden;
        @media screen and ( max-width: $lap-top-breakpoint ){
          width: 40px;
        }
      }
    }
  }
}
