@import "./../../variables";

.searchable-selectable-table-container {
  .widgets-container {
    display: flex;
    justify-content: space-between;
    .right-container {
      display: flex;
      justify-content: space-between;

      .refresh-button-container {
        display: flex;
        padding-left: 9px;
        margin-left: 5px;
        margin-bottom: 2px;
        margin-right: 2px;
        border-left: 1px solid $light-gray;
      }
    }
  }
}
