@import "../../variables";

.data-broker-details {
  margin-top: 70px;

  .more-info {
    width: 800px;
    margin-top: 40px;
    font-size: 14px;
    line-height: 1.5;
    color: $grey-7-primary-text
  }

  h3 {
    color: $primary-blue;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 30px;
  }

  .content-area {
    $content-width: 800px;

    .provider-types {
      margin-top: 15px;
      width: $content-width;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .form-inputs {
      width: $content-width;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 40px;
      .inputs {
        display: flex;
        .name-input {
          width: 320px;
        }
        .port-input {
          width: 100px;
          margin-left: 15px;
        }
      }

      .userCertificate {
        width: 100%;
        margin-top: 10px;
        padding-bottom: 80px;

        .certificateInputArea {
          .certificate-input {
            width: 320px;
          }

          .sertificateArea {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .certificate-input {
              width: 49%;
            }
          }

        }
      }
    }
  }
}
