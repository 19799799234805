@import "./../../widgets/card";
@import "./../../../variables";

$card-height: 425px;
$card-width: 100%;
$relationship-info-width: 330px;

#root .sync-dashboard {
  .relationship-card {
    @include card(true);
    width: $card-width;
    margin: 0 0 40px 0;
    border: none;
    .card-body {
      padding: 0;
      .relationship-card-content {
        display: flex;
        .relationship-info {
          width: $relationship-info-width;
          height: $card-height;
          background-color: $primary-blue;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          button.info svg {
            fill: white;
          }
          .left-pane {
            height: 322px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 24px;
            color: white;
            padding: 41px 38px;
            .icon {
              svg {
                width: 46px;
                height: 41px;
              }
            }
            .edit-credentials {
              font-size: 12px;
              font-style: italic;
              margin-top: 3px;
              display: flex;
              cursor: pointer;
              svg {
                width: 10px;
                fill: $white;
                margin-right: 3px;
              }
              &.disabled {
                filter: opacity(0.4);
                cursor: not-allowed;
              }
            }
            .text {
              padding-left: 10px;
              width: 86%;
              .title {
                font-weight: 600;
              }
              .data {
                display: flex;
                align-items: center;
                .name {
                  @include nowrap();
                }
                .Suc-popover-trigger-container {
                  margin-right: 10px;
                  button {
                    svg {
                      fill: $white;
                    }
                  }
                  &.active svg, &:hover svg{
                    fill: $white;
                  }
                }
                .Suc-tooltip-container.Suc-tooltip-info.target-settings-info {
                  white-space: normal !important;
                }

              }
              .data-broker-row {
                justify-content: space-between;
              }
            }
            .endpoint-container, .data-broker, .relationship-name {
              display: flex;
            }
          }
          .relationship-footer {
            border-top: solid 1px $light-gray;
            height: 50px;
            width: calc(100% - 80px);
            display: flex;
            margin: 6px auto;
            padding-top: 12px;
            font-size: 13px;

            .footer-part {
              color: white;
              height: 36px;
              text-align: center;
              margin-right: 10px;
              &:not(:first-child) {
                margin-left: 10px;
              }
              button {
                position: relative;
                top: 5px;
                svg {
                  top: -2px;
                }
              }
              .tags {
                svg {
                  fill: white;
                  width: 14px;
                  height: 14px;
                  top: 2px;
                  left: 4px;
                  margin-right: 10px;
                }
              }
            }
            .separator {
              color: white;
            }
          }
        }

        .sync-info-wide {
          background-color: $white;
          padding-left: 30px;
          width: calc(100% - #{$relationship-info-width});
          height: $card-height;
          padding-top: 28px;
          padding-right: 30px;

          .sync-info-header {
            display: flex;
            flex-direction: column;
            height: 65px;
            .estimated-time {
              /*
              position: absolute; //so the overall height won't change
              top: 23px;
              */
              font-weight: normal;
              font-size: 12px;
              color: $grey-7-primary-text;
              display: flex;
              width: 600px;
              svg {
                width: 16px;
                margin-left: 5px;
              }
            }

            .sync-status {
              display: flex;
              align-items: center;
              font-size: 15px;
              font-weight: 600;
              .status-icon {
                display: flex;
              }
              .status-info {
                position: relative; //to allow absolute positioning of the time estimation
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .status-text {
                  display: flex;
                  align-items: center;
                  .small-text {
                    margin-left: 5px;
                  }
                  .Suc-popover-trigger-container {
                    margin-left: 10px;
                  }

                  .aclCopy {
                    display: flex;
                    align-items: baseline;
                    font-size: 13px;

                    .aclNotification {
                      width: 13px;
                      height: 13px;
                    }
                  }
                }
                .estimated-time {
                  position: absolute; //so the overall height won't change
                  top: 23px;
                  font-weight: normal;
                  font-size: 12px;
                  color: $grey-7-primary-text;
                  display: flex;
                  width: 600px;
                  svg {
                    width: 16px;
                    margin-left: 5px;
                  }
                }
              }
              svg {
                position: relative;
              }
              &.syncing {
                color: $accent-blue-1;
                svg {
                  fill: $accent-blue-1;
                  width: 22px;
                }

                .aclNotification {
                  fill: $accent-blue-1;
                }
              }
              &.synced-done {
                color: $success;
                svg {
                  width: 22px;
                }

                .aclNotification {
                  fill: $success;
                }
              }
              &.failed {
                color: $error !important;
                svg {
                  width: 22px;
                }

                .aclNotification {
                  fill: $error;
                }
              }
              &.pending {
                color: #636363;
                .status-icon svg {
                  fill: #636363;
                  width: 22px;
                  margin-left: -10px;
                }

                .aclNotification {
                  fill: #636363;
                }
              }
            }
          }

          .failed {
            color: $error;
          }

          .sync-info-failure {
            border-top: solid 1px #d7d7d7;
            height: 80%;
            &.no-border {
              border-top: none;
            }
            .failure-message {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              .view-details {
                display: flex;
                margin-top: 15px;
                gap: 7px;
                font-weight: 600;
              }
            }
          }
        }
      }
      .notification-item {
        &:not(.relationship-card-notification) {
          max-width: 840px;
        }
      }
    }
  }
}



