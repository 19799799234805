@import "../../variables";

.Suc-default-dialog-layout.new-bucket-dialog {

  .new-bucket-instructions {
    display: flex;
    flex-direction: column;
  }
  .bucket-name {
    margin-top: 25px;
  }
}
