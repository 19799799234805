@import "./../../../variables";

$defaultAnimationDuration: 500ms;
$defaultAnimationDelay: 100ms;

@keyframes slideFromRight {
  from {
    transform: translate3d(-150%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.multiple-columns-graph {
  display: flex;
  &.multiple-columns-3 {
    gap: 60px;
  }
  &.multiple-columns-2 {
    gap: 40px;
  }
}

.horizontal-bars-graph {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .multiple-columns-2 &{
    gap: 30px;
  }
  .multiple-columns-3 &{
    gap: 30px;
  }
  .horizontal-bar {
    display: flex;
    flex-direction: column;
    gap: 0;
    font-size: 14px;
    color: $grey-7-primary-text;
    .multiple-columns-2 &{
      width: 255px;
    }
    .multiple-columns-3 &{
      width: 360px;
    }
    .horizontal-bar-info {
      display: flex;
      justify-content: flex-end;
      .multiple-columns-2 &{
        justify-content: space-between;
      }
      .multiple-columns-3 &{
        justify-content: space-between;
      }
    }
    .horizontal-bar-data {
      display: flex;
      justify-content: space-between;
      .name {
        width: 65px;
        margin-right: 10px;
      }
      .data-container {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 7px;
        margin: auto;
        background-color:  #e0e0e0;
        border-radius: 25px;
        .data {
          border-radius: 25px;
          background-color: #1fa0ff;
        }
        .fill {
          background-color:  #e0e0e0;
        }
      }

    }
  }

}


