.additionalSettingsUINew {
    .setup-container {
        .additionalSettings {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
        }

        .blob-tier-container {
            .title {
                margin-bottom: 5px;
            }
        }
    }
}