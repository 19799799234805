@import "../../../variables";
@import "../../../mixins";

#root .vol-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 30px;

  .no-volumes {
    margin-top: 77px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #acacac;
    font-size: 18px;
    .no-volumes-icon {
      width: 45px;
      margin-bottom: 13px;
    }
  }
  .nothing-found svg {
    width: 45px;
  }
  .volumes-table {
    .notice-container {
      a {
        margin: 0 4px;
      }
    }
    table.standard {
      width: $dashboard-width;
      table-layout: fixed;
      thead {
        background: #4ca0ff;
        td {
          padding-left: 30px;
          padding-right: 10px;
          border-right: 0.2px solid rgba(255, 255, 255, 0.2);
        }
        svg {
          width: 15px;
          margin-right: 0;
          margin-left: 5px;
        }
      }
      tbody td {
        padding-left: 30px;
        padding-right: 10px;
        @include nowrap();
        max-width: 300px;
      }
    }
  }

}
