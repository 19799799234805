@import "./../../../variables";
@import "./../../../mixins";
@import "./../../widgets/card";

$groups-width: 1217px;

.groups-notification.notification-item {
  position: fixed;
  bottom: 80px;
  width: 840px;
  left: calc(50% - 420px);
  z-index: 1000;
  @media screen and ( max-width: 1500px ){
    bottom: 20px;
  }
}


.manage-groups {
  position: relative;
  margin: 0 auto;
  &:not(.data-mover-step) {
    max-width: $groups-width;
    .widgets-container {
      width: 990px;
      padding: 20px 0 40px 0;
      .item-count-widget {
        .title {
          font-size: 24px;
        }
        color: $primary-blue;
      }
    }
    .add-container {
      padding-left: 24px;
      border-left: 1px solid  #d8d8d8;
      position: absolute;
      right: 0;
      top: 20px;
    }
  }
  .managed-data-broker-buttons {
    .garbage-bin {
      svg {
        max-width: 17px;
      }
    }
  }
}
