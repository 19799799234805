@import "../../variables";


.sort-widget {
  display: flex;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  .sort-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
