@import "./../../variables";

.widgets-cards-container {
  width: 100%;
  transition: width $default-transition-timing;

  .widgets-container{
    margin-bottom: 5px;
    display: flex;
    .widgets {
      display: flex;
      gap: 15px;
      width: 50%;
      &.right {
        justify-content: flex-end;
      }
      &.left {
        gap: 30px;
        justify-content: flex-start;
      }
      .filter-widget-container {
        .filter-button {
          svg {
            g {
              fill: $light-blue;
            }
          }
          &.active {
            g {
              fill: $darker-blue;
            }
          }
          &.cards {
            svg {
              top: 4px;
            }
          }
        }
      }


    }

    .search-widget.open{
      border-bottom: 1px solid $primary-blue;
    }
  }
}
