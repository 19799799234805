@import "./../../../variables";
@import "../../../mixins";


.header-item {
  padding-right: 12px;
  margin: 10px 20px 0 0;
  min-width: 140px;
  max-width: 280px;
  height: 40px;
  font-size: 14px;
  letter-spacing: 0.35px;

  &:not(:last-child) {
    border-right: 1px solid $grey-4-disabled;
  }

  .header-item-title {
    @include nowrap();
    color: #a7a7a7;
  }

  .header-item-data {
    color: $dark-gray;

    a.errors {
      color: #DA1E27;
      top: -2px;
    }

    @include nowrap();

  }
}
