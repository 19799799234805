@import "./../../variables";
@import "./../../mixins";

#root {
  .wizard & {
    .wizard-loading {
      height: 100%;
      position: relative; //for positioning the loading bar
      display: flex;
      justify-content: center;
      align-items: center;
      .loading-bar {
        top: 0;
      }
    }
    .rsw_2Y {
      position: static;
    }
    .rsw_3G {
      position: absolute;
      height: calc(100% - 56px);
    }
    .full-height {
      height: 100%;
      &.new-wizard {
        margin-top: 56px;
        .wizard-loading {
          top: -14px;
        }
      }
    }
  }

  section.content {
    .wizard & {
      .wizard-step, .select-direction-step {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        form.wizard-step-form {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .scrollable-area {
          &.up {
            @include wizard-step-animation-up();
          }
          &.down {
            @include wizard-step-animation-down();
          }
        }

        .searchable-selectable-table-container {
          max-width: 1440px;
          margin: 0 auto;
        }

        .nothing-found {
          padding-top: 160px;

          svg {
            fill: #d8d9d9;
            margin-bottom: 22px;
          }

          p {
            margin-bottom: 0;
          }

          color: #b7b7b7;
          font-size: 18px;
          width: 260px;
          margin: 0 auto;
          text-align: center;
        }
      }

      .how-it-works-step h3 {
        margin-bottom: 40px;
      }
    }
  }
}
