@import "./../../../variables";
@import "./../../../mixins";

$settings-width: 950px;

.settings-step {
  .header-text {
    text-align: center;
    margin: 15px 0 35px;
  }
}

.settings-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  .settings-header {
    margin-top: 20px;
    width: $settings-width;
  }
  .scrollable-area{
    @include wizard-step-animation-up();
  }
  .settings-content {
    width: $settings-width;
    margin: 0 auto;
  }
  h1 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 23px;
  }
  .relationship-info-container {
    display: flex;
    .endpoint-info {
      max-width: 45%;
      display: flex;
      align-items: center;
      &:last-of-type {
        margin-left: 40px;
      }
      .icon {
        height: 35px;
        margin-right: 7px;
        svg {
          height: 35px;
        }
      }
      .info{
        color: #264c72;
        @include nowrap();
      }
    }
  }
}

.sync-settings {
  width: $settings-width;
  margin: 0 auto 20px;
  .category-title {
    color: $primary-blue;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .reset-click {
    color: $primary-blue;
    cursor: pointer;
    text-align: left;
    margin-top: 12px;
    width: fit-content;
    svg {
      position: relative;
      top: 2px;
      width: 18px;
      margin-right: 7px;
    }
    &:hover {
      color: $primary-blue-hover;
      svg .path {
        fill: $primary-blue-hover;
      }
    }
  }
  .accordion-container {
    .Suc-field-extra {
      visibility: hidden; //we don't want to show the original errors on the fields
    }
      //placeholder to allow the radio buttons to be out of alignment with text (not to use in radio buttons components)
    .placeholder {
      position: relative;
      margin-left: 21px;
    }

    padding: 25px;
    margin-bottom: 2px;
    background-color: white;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    .chevron {
      width: 16px;
      height: 16px;
      svg {
        fill: #b9b9b9;
      }
      &.up {
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
    .title{
      cursor: pointer;
      width: 215px;
      font-weight: 600;
    }
    .middle {
      width: 658px;
      max-height: 25px;
      overflow: hidden;
      transition: max-height 0.6s ease;
      &.show-content {
        max-height: 700px;
        overflow: visible;
      }

      .summary-text {
        @include nowrap(80%);

        .fileTypeSummary {
          display: flex;
          flex-direction: row;

          .warning {
            margin-top: 1px;
          }
        }
      }

    }
    .instructions {
      margin-bottom: 15px;
    }
    .end {
      margin-left: auto
    }

    input[type="checkbox"] {
      vertical-align: middle;
      margin: 0 0 2px 0;
    }

    &:hover {
      .title:not(.error){
        color: $primary-blue;
      }
      .chevron svg {
        fill: $primary-blue;
        cursor: pointer;
      }
    }

    .radio-buttons {
      height: auto;
    }

    .summary-area {
      cursor: pointer;
    }
    .input-area, .summary-area {
      display: none;
      &.show{
        display: block;
      }
    }

    .error {
      color: $error;
      svg {
        width: 16px;
        fill: $error;
        position: relative;
        top: 1px;
      }
      .error-text {
        display: inline-block;
        margin-left: 5px;
      }
    }
    .input-area .error-area {
      font-size: 13px;
      margin-top: 30px;
      color: $error;
      font-weight: 600;
      height: 15px;
    }

    .disable-change{
      opacity: .5;
      pointer-events: none;
      input {
        background: none;
      }
    }

    //errors are shown for the entire section, not for a specific field
    .form-error {
      display: none!important;
    }
    .Suc-input-extra {
      display: none;
    }

    .inline-field {
      display: inline-block;
      margin: 0 7px;
      width: 90px;
    }

    .Suc-Select-container.inline-field  {
      .Suc-Select__control {
        min-height: 27px;
        height: 27px;
        .Suc-Select__value-container {
          padding: 0;
        }
      }
    }


    //***** accordion type-specific:

    &.schedule, &.abort{
      .radio-buttons {
        display: flex;
        >div {
          margin-right: 50px;
        }
      }
      .note {
        font-style: italic;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .hidden {
        visibility: hidden;
      }
      .schedule-definition {
        height: 38px;
        display: flex;
        align-items: flex-end;
        .text {
          width: 260px;
        }
        .user-input {
          display: flex;
          align-items: flex-end;
        }
      }
      .input-area {
        .warning {
          font-size: 13px;
          font-style: italic;
          display: flex;
          color: $warning;
        }
      }
    }

    &.retries {
      .summary-text {
        position: relative;
        top: 2px; //this is a correction that is needed in order to be in the same position as the input-area
      }
      .placeholder {
        bottom: 11px;
        position: relative;
      }
    }

    &.grace-period {
      .summary-text {
        position: relative;
        top: 2px; //this is a correction that is needed in order to be in the same position as the input-area
      }
      .row {
        height: 60px;
      }
      .grace-value-column {
        width: 100px;
        margin-left: 212px;
        margin-right: 5px;
      }
      .grace-resolution-column {
        width: 100px;
        margin-right: 3px;
        position: relative;
        bottom: 3px;
      }
      .last-part {
        position: relative;
        left: 4px;
      }
      .radio-buttons {
        .auto-sync {
          float: left;
          margin-right: 25px;
          position: relative;
          label {
            position: absolute;
            width: 270px;

          }
          &:nth-of-type(2) {
            label {
              top: 40px;
              width: 500px;
            }
          }
        }
      }
      .input-area {
        .warning {
          font-size: 13px;
          font-style: italic;
          display: flex;
          color: $warning;
        }
      }
    }

    &.delete-on-target, &.delete-on-source {
      .warning {
        display: inline-block;
        svg {
          width: 16px;
          fill: $warning;
          margin-right: 5px;
        }
      }
      .summary-text {
        position: relative;
        top: 2px; //this is a correction that is needed in order to be in the same position as the input-area
      }
      .input-area {
        .warning {
          font-size: 13px;
          font-style: italic;
          display: flex;
          color: $warning;
        }
      }
    }

    &.delete-on-source {
      .warning svg , .input-area .warning {
        fill: $error;
        color: $error;
      }
    }

    &.file-types {
      label {
        display: inline-block;
        margin-right: 40px;
        span {
          margin-left: 5px;
        }
      }

      .warning {
        margin-top: 15px;
        font-size: 13px;
        font-style: italic;
        display: flex;
        color: $warning;

        svg {
          width: 16px;
          fill: $warning;
          margin-right: 5px;
        }
      }
    }

    &.files-extensions {
      .field-container {
        width: 507px;
      }
      .warning-message{
        color: $warning;
      }
      .info-message {
        color: $primary-blue;

      }
      .field-bottom-note {
        position: absolute;
        font-style: italic;
        font-size: 13px;
      }
    }

    &.file-size {
      .radio-buttons {
        display: flex;
        .file-size-radio:last-child {
          margin-left: 30px;
        }
      }
      .file-size-row {
        display: flex;
        align-items: flex-end;
        height: 40px;
        .row-title {
          width: 92px;
        }
        .file-size-value {
          width: 100px;
        }
      }
      .error-area.placeholder {
        margin-left: 0;
      }
      .file-size-notice {
        margin-top: 20px;
        width: 90%;
        .file-size-label {
          margin-right: 5px;
          color: $warning;
          font-weight: 600;
        }
      }
    }

    &.date-modified {
      .input-area.show {
        height: 405px;
      }
      .calendars-row {
        display: flex;
        height: 300px;
        .calendar-item{
          width: 43%;
        }
      }
      .Suc-Select-container {
        width: 520px;
        margin-bottom: 20px;
      }
      .input-area {
        .warning {
          font-size: 13px;
          font-style: italic;
          display: flex;
          color: $warning;
          position: absolute;
          top: 140px;
        }
      }
    }

    &.acl {
      .acl-notice {
        margin-top: 20px;
        width: 90%;
        .acl-notice-label {
          margin-right: 5px;
          color: $warning;
          font-weight: 600;
        }
      }

      .Suc-Select-container {
        width: 520px;
      }
    }
    &.continuousSync{
      .continuousSync-notice {
        margin-top: 20px;
        width: 90%;
        .continuousSync-label {
          margin-right: 5px;
          color: $warning;
          font-weight: 600;
        }
      }
    }
    &.directories-names{
      .field-container {
        width: 507px;
      }
      .warning-message{
        color: $warning;
      }
      .info-message {
        color: $primary-blue;

      }
      .field-bottom-note {
        position: absolute;
        font-style: italic;
        font-size: 13px;
      }
      .directories-names-notice {
        margin-top: 20px;
        width: 90%;
        .directories-names-label {
          margin-right: 5px;
          color: $warning;
          font-weight: 600;
        }
      }
    }
    &.notifications{
      label {
        display: inline-block;
        margin-right: 40px;
        span {
          margin-left: 5px;
        }
      }
      .notifications-notice {
        margin-left: 21px;
        margin-top: 20px;
        width: 90%;
        .notifications-label {
          margin-right: 5px;
          color: $warning;
          font-weight: 600;
        }
      }

    }
  }
}
