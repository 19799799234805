#root {
  .line {
    display: flex;
    gap: 40px;
    border-bottom: 1px solid #d7d7d7;;
  }
  .credentials-container.box {
    padding-top: 10px;
    .credentials-title {
      margin-bottom: 14px;
      margin-top: 14px;
    }
    .credentials-fields {
      .Suc-textarea {
        textarea {
          height: 142px;
          white-space: pre-wrap;
        }
      }
    }
  }
}
