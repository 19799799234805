.encryptionSetting {
    padding-right: 40px;
    flex: 1;

    .encryptTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid var(--border);
        padding-bottom: 5px;
        margin-bottom: 15px;

        .encryptIcon {
            height: 14px;
            margin-right: 10px;
        }
    }

    .customKey {
        [class*=FieldErrorWarning-module] {
            display: flex;
            align-items: baseline;
            max-width: unset;
            height: unset;

            svg {
                position: relative;
                top: 4px;
            }

            [class*=Typography-module_base]:last-child {
                white-space: break-spaces;
                word-break: break-all;
            }
        }
    }
}