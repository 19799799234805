@import "../../variables";

.notice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  .notice-icon {
    display: flex;
    svg {
      width: 24px;
      margin-right: 14px;
    }
  }
  .notice-body .notice-title {
    margin-right: 7px;
  }
  &.warning .notice-title {
    color: $warning;
  }
  &.error .notice-title {
    color: $error-color;
  }
}
