@import '../../../variables';
@import '../../../mixins';

.wizard.no-existing-brokers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 75px;
  button.how-it-works {
    align-self: flex-start;
  }
  .title {
    color: $grey-7-primary-text;
    font-size: 24px;
    margin: 40px 0;
  }
  svg {
    width: 55px;
  }
  .add-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:8px;
    span {
      font-size: 30px;
      font-weight: lighter;
      padding-bottom: 6px;
    }
  }
}
.data-mover-step .add-broker-button {
  width: 40px;
  min-width: 40px;
  font-size: 40px;
  font-weight: 300;
  padding-bottom: 10px;
}
#root .data-brokers.widgets-cards-container {
  width: 1200px;
  margin: 20px auto 0;
}
