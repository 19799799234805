@import '../../variables';

.reload-message {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  padding: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .frame {
    width: 550px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    padding: 34px 68px;
    p {
      font-size: 14px;
      text-align: center;
      &.text-color {
        color: $primary-blue-hover;
        font-weight: 600;
      }
    }
  }
}