@import "./../../../variables";
@import "./../../../mixins";

.relationship-tags-step {
  .object-tagging {
    display: flex;
    width: 400px;
    margin: 0 auto;
    justify-content: space-around;
  }
  .tags-content {
    text-align: center;
    margin-top: 15px;
  }
  p {
    margin:  0 auto 10px auto;
  }
  .note {
    font-size: 13px;
  }

  .tag {
    position: relative;
    &:first-child {
      border-top: 1px solid $medium-gray;
    }
    border-bottom: 1px solid $medium-gray;
    padding: 20px 0 10px 0;
    .columns {
      &.key {
        padding-left: 0;
        padding-right: 30px
      }
      &.value {
        padding-right: 0;
        padding-left: 30px;
      }
    }
  }

  div.close {
    position: absolute;
    right: -60px;
    top: 48px;
    &:hover {
      cursor: pointer;
    }
    svg{
      width: 30px;
      height: 30px;
      fill: $medium-gray;
    }
  }

  .add-tag {
    position: relative;
    padding-top: 20px;
    min-height: 55px;
    div {
      float: left;
    }
    .info {
      position: absolute;
      right: 0;
    }

    .circle {
      &:hover {
        cursor: pointer;
      }
      text-align: center;
      color: $white;
      border-radius: 50%;
      background-color: $primary-blue;
      width: 23px;
      height: 23px;
      margin-right: 10px;
    }
  }

}
