.permissionsStep {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .permissionStepHeader {
        padding: 40px 0;

        .permissionsTitle {
            text-align: center;
            color: var(--text-title);
            margin-bottom: 15px;
        }
    }

    .stepForm {
        flex: 1;
        width: 360px;
    }
}