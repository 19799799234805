@import "../../../variables";
@import "../../../mixins";

.Suc-tooltip-container.Suc-tooltip-info.s3-private-links-warning {
  width: 300px;
  position: absolute;
  span {
    b {
      color: #eb6d12;
    }
  }
}
#root .show-buckets-step {
  .tags {
    margin-right: 15px;
  }

  .header-text {
    text-align: center;
    margin: 15px auto;
  }

  .bucket-setup {
    width: 880px;
    height: 520px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .bucket-info {
      margin-top: 55px;

      .title {
        margin-bottom: 6px;
      }

      .bucket-name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 880px;
        height: 70px;
        padding: 0 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        color: $dark-gray;
        .bucket-name {
          display: flex;
          align-items: center;
          &.s3 {
            svg {
              width: 40px;
              margin-right: 0;
            }
          }
          svg {
            width: 30px;
            margin-right: 20px;
          }
        }
        .close-step {
          svg {
            width: 15px;
            fill: $light-gray;
          }
          &:hover {
            cursor: pointer;
            svg {
              fill: black;
            }
          }
        }
      }
    }

    .setup-container {
      margin-top: 46px;
      .s3-private-links-container {
        position: relative;
        .Suc-tooltip-info-container {
          position: absolute;
          left: 115px;
          top: 3px;
          z-index: 1000;
          svg {
            fill: #eb6d12;
          }
        }
      }
      .encryption-container {
        width: 400px;
        float: left;

        .title {
          border-bottom: 1px solid $light-gray;
          font-size: 14px;
          font-weight: bolder;
          margin-bottom: 10px;
          padding-bottom: 5px;
          display: flex;
          justify-content: space-between;
          .key-and-text {
            display: flex;
            align-items: center;
            svg {
              width: 24.5px;
              height: 12.5px;
              margin-right: 10px;
            }
          }
          .encryption-switch {
            display: flex;
          }
        }
        input {
          margin-bottom: 0;
        }
      }

      .storage-class-container {
        width: 430px;
        float: right;

        .Select__menu-list {
          max-height: 230px;
        }
      }

      .blob-tier-container {
        width: 430px;
        float: left;
      }

    }
  }

  .nothing-found svg {
    width: 71px;
  }

  button.new-bucket {
    width: fit-content;
    padding: 0 10px;

    svg {
      top: 2px;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      position: relative;
    }

    &:hover, &:focus {
      svg {
        fill: $white;
      }
    }
  }

  table {
    .bucket {
      svg {
        width: 22px;
        height: 23.3px;
      }
    }

    .region {
      width: 356px;

      svg {
        width: 17px;
        height: 26.45px;
      }

      span:nth-child(2) {
        margin: 0 10px;
      }
    }
  }
}
