@import "../../../variables";

#root .credentials-container.acl-selection {
  user-select: none;
  padding: 32px 0;
  border-top: 1px solid #d7d7d7;

  .acl-title {
    font-weight: 600;
    display: flex;
    align-items: center;

    > span {
      margin-right: 7px;
    }
  }

  .acl {
    margin: 32px 0;
    display: flex;
    align-items: center;

    .acl-check-box {
      margin-bottom: 0;

      input[type="checkbox"] {
        margin-bottom: 0;
        height: auto;
      }
    }

    label {
      margin-left: 7px;

      &.disabled {
        color: $grey-5;
      }
    }
  }

  .notices {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .notice-container {
      justify-content: flex-start;
    }
  }
}
