@import "./../../../variables";
@import "./../../../mixins";

.Suc-tooltip-container.Suc-tooltip-info.data-broker-warning {
  min-width: 300px;
  span {
    b {
      color: #eb6d12;
    }
  }
}

.Suc-tooltip-container.Suc-tooltip-info.settings-info {
  min-width: 500px;
}

body.tab #root {
  .group-settings {
    max-width: $dashboard-width;
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: column;

    .bread-crumbs {
      position: relative;

      display: flex;
      .back {
        font-size: 14px;
        color: $primary-blue;

        &:hover {
          color: $primary-blue-hover;
          cursor: pointer;
        }
      }

      .right-arrow {
        height: 20px;

        svg {
          vertical-align: middle;
          width: 8px;
          height: 12px;
          margin-right: 11px;
          margin-left: 11px;
        }
      }

      .path {
        font-size: 14px;
        color: $dark-gray;
        max-width: 600px;
        @include nowrap();
      }
    }

    .group-settings-card {
      margin-top: 24px;
      width: 1217px;
      height: 100%;
      padding: 32px 32px 32px 31px;
      box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
      background-color: $white;

      .Suc-tooltip-info-container {
        position: relative;
        top: 3px;
        left: 5px;
      }

      .group-settings-values {
        border-bottom: solid 1px #979797;
        height: 185px;

        .values-title {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.57;
          color: $primary-blue;
        }

        .settings-container {
          margin-top: 12px;
          display: flex;
          justify-content: flex-end;
          gap: 65px;

          .field {
            position: relative;
            .Suc-text-input {
              width: 145px;
              .Suc-field-extra {
                width: 200px;
              }
            }

            input {
              width: 140px;
              margin-top: 10px;
            }

            label {
              font-size: 14px;
              line-height: 1.57;
              color: $dark-gray;

            }
          }
        }
      }

      .data-brokers-values {
        margin-top: 20px;

        > div {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.57;
          color: $primary-blue;
        }

        .data-brokers {
          width: 1153px;
          height: 240px;
          margin: 24px 0 32px 1px;
          background-color: #f5f5f5;
          .scrollable-area {
            padding-top: 0 !important;
          }

          .searchable-selectable-table-container .standard {
            thead {
              background-color: #f5f5f5;

              span {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.57;
                color: $dark-gray;
              }
            }

            tbody {
              tr {
                background-color: #f5f5f5;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.57;
                color: $dark-gray;

                &:hover {
                  box-shadow: none;
                }

                td.name {
                  div.name {
                    max-width: 120px;
                    @include nowrap();
                    float: left;
                  }
                  .Suc-tooltip-info-container {
                    position: relative;
                    top: 3px;
                    left: 4px;

                    svg {
                      fill: #eb6d12;
                    }
                  }
                }
                td.status {
                  svg {
                    vertical-align: middle;
                  }
                }

              }
            }

          }
        }
      }

      .group-settings-buttons-container {
        display: flex;
        flex-direction: column;
        .buttons-error {
          margin-bottom: 10px;
        }
        .group-settings-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 16px;
        }
      }

    }

  }
}
