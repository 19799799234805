@import "../../../variables";
@import "../../../mixins";

.show-export-step {
  .header-text {
    text-align: center;
    margin: 15px auto;
  }

  .nothing-found svg {
    width: 84px;
  }

  table {
    .path.large {
      .path {
        margin-bottom: 7px;
      }
    }

    span.divider {
      margin-left: 5px;
      margin-right: 5px;
    }

    .selected .counts {
      color: $tertiary-color;
    }

    .counts {
      color: #959595;
      font-size: 12px;
      font-weight: bolder;
    }
  }

  button.new-export {
    width: 200px;
    svg {
      top: 2px;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      position: relative;
      fill: $primary-blue;
    }
    &:hover, &:focus {
      svg {
        fill: $white;
      }
    }
  }
}
