@import "../../variables";

.proxy-step {
  .title span{
    font-weight: normal;
    font-style: italic;
  }
  .content-area {
    .notice-container {
      width: 560px;
    }
    .proxy-section {
      width: 360px;
    }
    .proxy-host {
      margin-top: 30px;
    }
    .credentials-section {
      margin-top: 30px;
      .section-switch {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        input {
          display: none;
        }
        label {
          margin-left: 0;
        }
        svg {
          width: 12px;
          fill: $grey-5;
          margin-left: 10px;
          position: relative;
          top: 3px;
          &:hover {
            fill: $grey-6;
          }
          &.up {
            top: 0;
            transform: rotate(180deg);
          }
        }
      }
      .credentials {
        height: auto;
        max-height: 300px;
        transition: max-height 0.2s linear;
        overflow: hidden;
        &.closed {
          max-height: 0;
        }
      }
    }
  }
}