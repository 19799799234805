@import "../../../variables";

.subscribe {

  .icon-with-title {
    svg {
      width: 22px;
      height: 22px;
      fill: $dark-blue;
      margin-right: 10px;
      margin-bottom: -5px;
    }
    h5 {
      display: inline;
    }
    &.failed {
      svg {
        fill: $error-color;
      }
      h5 {
        color: $error-color;
      }
    }
    margin-bottom: 0.5rem;
  }

}