@import "../variables";

body {
  //overflow-y: hidden;

  &.no-portal-widget {
    #portal-widget-frame-container {
      display: none;
    }
  }

  &.login {
    min-height: 640px;
    header.primary {
      display: none;
      &.show {
        display: block;
      }
    }
    #portal-widget-frame-container {
      display: none;
    }

    header.secondary {
      display: none;
    }

    .cm-header {
      display: none;
    }

    section.content {
      top: 62%;
      width: 100%;
    }
  }


  &.tab {
    background-color: $tab-background;

    section.content {
      height: 100%;
    }
  }

  &.wizard {
    background-color: $wizard-background;
  }

  &.cm-integration {
    background-color: #f1f1f1;
  }
}

#root {
  overflow-x: hidden;

  //this should be outside "content wrapper", for render during startup
  header.primary {
    height: $primary-header-height;
    z-index: $header-z;
    position: relative;
    flex: 0 0 $primary-header-height;
  }

  .fixed-footer {
    position: fixed;
    bottom: 0;
    height: $footer-height;
    width: 100%;
  }

  .content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    section#primary {
      overflow: hidden;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      .secondary-shadow {
        height: 56px;
        box-shadow: $box-shadow-default;
        z-index: 2;

        header.secondary {
          height: 100%;
          width: 100%;
          position: relative; //so the loading bar will be relative to the header
          z-index: $header-z - 1;
          background-color: $white;

          h1, h2 {
            width: 100%;
            margin-bottom: 0;
            color: $primary-blue;
            font-weight: lighter;
            text-align: center;
          }

          h1 {
            font-size: 40px;
          }

          @media screen and (max-width: $small-resolution) {
            h1 {
              font-size: 32px;
              font-weight: normal;
            }

            h2 {
              font-size: 22px;
              font-weight: normal;
            }
          }
        }
      }

      section.content {
        transition: height $default-transition-timing;
        overflow: hidden;
        height: 100%;
        .scrollable-area {
          padding-top: 20px;
          flex: 1;
          overflow: auto;
          height: 100%;
          width: 100%;
          @include default-scrollbar();
        }

        h3 {
          font-weight: normal;
          text-align: center;
          font-size: 24px;
        }
      }
    }
    .footer-container {
      flex: 0 0 $footer-height;
    }
  }
}
