@import "./../../variables";

.item-count-widget{
  color: $primary-blue;
  font-weight: normal;
  float: left;
  .darkula &{
    color: $darker-blue;
  }
  .count{
    font-size: 25px;
    margin-right: 10px;
    .cm-integration &{
      font-size: 20px;
    }
  }

  .title{
    font-size: 15px;
    margin-right: 10px;
    .cm-integration &{
      font-size: 20px !important;
    }
  }
  .view-all {
    border-left: 1px solid $dark-blue;

    .Suc-button.Suc-secondary.view-all-button {
      display: inline-flex;
      font-size: 13px;
      text-align: center;
      color: $dark-blue;
      border: none;
      min-width: 70px;
      background-color: transparent;
    }
  }
}
