@import "../../variables";
@import "../../mixins";


@mixin card($largeCard: false) {
  box-shadow: 0 2px 2px 0 rgba(187, 187, 187, 0.5);
  line-height: 1.5;
  position: relative;
  border: solid 1px transparent;

  .collapse-button {
    &:hover {
      cursor: pointer;
    }
    position: absolute;
    right: 85px;
    top: 39px;
    z-index: 499;
    svg {
      width: 25px;
      height: 25px;
      fill: #b9b9b9;
    }
  }

  .card-error {
    font-size: 13px;
    font-weight: bolder;
    color: $error;
    position: absolute;
    bottom: -24px;
    left: 0;
    text-align: center;
    width: 100%;
  }

  &.selectable {
    &:hover {
      background-color: $item-hover;

      .cm-integration & {
        background-color: $white;
        box-shadow: 4px 4px 15px 0 rgba(187, 187, 187, 0.5);;
      }
    }
  }

  &.selected {
    border: solid 1px $primary-blue;
  }

  :not(.horizontal) > .text {
    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }

  .horizontal .text {
    float: left;

    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .text {
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .card-content {
    .failed {
      .icon svg {
        fill: $medium-gray;
      }

      .text {
        > div:first-child {
          color: $error;
        }
      }
    }

    table {
      table-layout: fixed;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      tbody {
        background-color: transparent;
      }

      tr {
        background-color: transparent;
      }

      td {
        padding-bottom: 19px;

        :first-child {
          font-size: 14px;
        }

        :last-child {
          font-size: 12px;
          color: $grey-6;
        }
      }
    }
  }

  @if ($largeCard) {
    .card-body {
      padding: 25px 41px 0 41px;
      height: 100%;
      width: 100%;
      line-height: 1.5;
      text-align: left;
      position: relative;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .card-content {
      padding-top: 20px;
      position: relative;
    }

    .card-header {
      height: 36px;
      width: 97%;
      border-bottom: 1px solid #c2c2c2;
      line-height: 14px;
      position: relative;
      font-size: 14px;

      .loading-bar {
        height: 2px;
        bottom: -1px;
        border-bottom: 1px solid #cbcdd0;
      }

      .the-blue {
        height: 2px;
        bottom: 0;
      }
    }

    &.menuable {
      .card-menu .Suc-popover-trigger-container {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 24px;
        right: 24px;
        z-index: 2;
      }
    }
  }
}
