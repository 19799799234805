@import "../../variables";

.enforcement-button {
  display: flex;
  justify-content: center;
  button {
    width: 296px;
    height: 44px;
    border-radius: 2px;
    color: $white;
    font-size: 14px;
    background-color: #58d2b9;;
  }
  &.warning button {
    background-color: $warning;
  }
  &.error button {
    background-color: $error;
  }
}
