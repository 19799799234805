@import "./../../../variables";
@import "../../../mixins";

.relationship-name {
  display: flex;
  .text {
    padding-left: 10px;
    width: 86%;
    .title {
      font-weight: 600;
    }
    .data {
      display: flex;
      align-items: center;
      .name {
        @include nowrap();
        max-width: 170px;
      }
      &.blue {
        color: $dark-blue;
      }
      &.in-table {
        padding-top: 10px;
      }
    }
  }

  svg.pencil {
    width: 12px;
    height: 14px;
    fill: $white;
    margin-left: 6px;
    &:hover {
      cursor: pointer;
    }
    &.inverted {
      fill: $primary-blue;
    }
  }
  &.disabled {
    .text .data {
      .name {
        opacity: 0.5;
      }
      svg.pencil{
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

}
