@import "../../variables";
@import "../../mixins";

$button-panel-height: 70px;

.button-with-notification {
  flex: 0 0 $button-panel-height;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .more-info {
    position: absolute;
    bottom: 30px;
    left: 17%;
    color: $dark-blue;
  }
  &.white {
    border-top: 1px solid #e1e1e1;
    background-color: $white;
  }
  &.transparent {
    background-color: transparent;
    box-shadow: $box-shadow-default;
  }

  .secondary-button {
    margin-left: 20px;
  }
  .notification-container {
    position: absolute;
    bottom: 90px;
    width: $notification-width;
  }
}
