@import "../../variables";

.right-panel-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .right-panel-layout-header {
    padding: 15px 40px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    .right-panel-layout-header-title {
      font-size: 18px;
      color: $primary-blue;
      display: flex;
      align-items: center;
      .Suc-tooltip-info-container {
        margin-left: 5px;
      }
    }
    .right-panel-layout-header-help {
      margin-left: auto;
      margin-right: 15px;
    }
    .right-panel-layout-header-close {
      svg {
        width: 16px;
        fill: $grey-7-primary-text;
      }
      &:hover svg {
        fill: scale-color($grey-7-primary-text, $lightness: -20%);
      }
    }
  }
}
