@import "./../../../variables";
@import "./../../../mixins";

#root .relationship-step {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3.review-title {
      margin-top: 65px;
      position: relative;
    }
    .relationship-name {
      width: 280px;
      display: flex;
      justify-content: space-between;
      input {
        width: 210px;
        border: 1px solid #A7A7A7;
        border-radius: 4px;
        background-color: #ffffff;
        font-size: 14px;
        padding: 11px 16px;
        box-sizing: border-box;
        height: 40px;
        appearance: none;
        caret-color: #006dc9;
      }
      label {
        margin-top: 9px;
      }
    }
  }

  .cards {
    width: 1092px;
    margin: 40px auto 30px;
    .end-points-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .data-broker-area {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 160px;
      .data-broker-container {
        display: flex;
        &>svg {
          width: 100px;
        }
        .data-broker-card-position {
          position: relative;
          top: 50px;
          margin: 0 15px;
        }
      }
      svg {
        fill: $primary-blue;
      }
    }
    svg.left-arrow,  svg.right-arrow {
      fill: $primary-blue;
    }
  }

  .secure-nfs-relationship {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .databrokers-function-pair {
      bottom: 35px;
      position: relative;
      margin-right: auto;
      margin-left: auto;

      .databroker-function {
        width: 130px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        font-size: 13px;
        color: #163f68;
        font-style: italic;
        &.target {
          margin-left: 127px;
        }
      }
    }

    .secure-relationship-description-pair {
      display: flex;
      justify-content: space-between;
      bottom: 25px;
      left: 17px;
      position: relative;
      margin-right: auto;
      margin-left: auto;

      .secure-relationship-description {
        display: flex;
        flex-direction: column;
        width: 426px;
        font-size: 13px;
        color: #163f68;

        div.field-name {
          display: flex;
          gap: 10px;
          div {
            &:first-child {
              font-weight: 600;
            }
            &:not(:first-child) {
              @include nowrap(260px);
            }
          }
        }
      }
    }
  }

  .additional-text {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    &.failed {
      color: $error;
      font-weight: bolder;
      font-size: 15px;
    }
    &.percentage {
      font-weight: lighter;
      font-size: 34px;
      color: $primary-blue;

    }
    &.pending {
      font-size: 20px;
    }
  }
}
