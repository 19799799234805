@import "../../../variables";
@import "../../../mixins";

#root .server-step {
  .version {
    color: $darker-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }

  .nfs-version-cb{
    width: 68px;
    margin-left: 10px;
    .Suc-Select__value-container {
      justify-content: center;
    }
  }

  .selected-server {
    margin: 20px auto;
    display: flex;
    align-items: center;
  }

  .new-nfs {
    margin: 20px 0;
    display: flex;
    align-items: center;
    .nfs-address {
      width: 404px;
    }
    >svg {
      width: 76px;
      margin-right: 25px;
    }

    &.selected {
      .nfs-address {
        margin-right: 22px;
      }
      input:read-only {
        border:none;
        background-color: $white;
        font-weight: bolder;
        margin: 0;
      }
    }
  }

  .hosts-server-table {
    margin-top: 18px;
    width: 520px;
    .select-header {
      width: 72%;
      text-align: left;
      margin-bottom: 10px;
    }
    .or-divider {
      width: 100%;
      height: 31px;
      position: relative;
      margin-bottom: 25px;
      .circle {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: $primary-blue;
        left: calc(50% - 15px);
        position: absolute;
        color: #fff;
        text-align: center;
        line-height: 29px;
      }

      .left-border, .right-border {
        position: absolute;
        top: 15px;
        width: 228px;
        height: 1px;
        background-color: #dadada;
      }

      .left-border {
        left: 0;
      }

      .right-border {
        right: 0;
      }

    }
    .Select__header {
      width: 64%;
      margin: auto;
      text-align: center;
    }
  }
}

#root .server-step .credentials-container {
  display: flex;
  flex-direction: column;
  width: 800px;
  margin: 0 auto;
  //border-top: 1px solid $light-gray;
  padding: 32px 0;

  .credentials-title {
    font-weight: bolder;
    margin-bottom: 24px;
  }
  .credentials-fields {
    display: flex;
    justify-content: space-evenly;
    gap: 40px;
  }
  &.azure {
    width: 642px;
  }
  &.box {
    .pane {
      width: 380px;
      display: flex;
      flex-direction: column;
    }
  }
}
