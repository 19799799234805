@import "../../variables";
@import "../../mixins";

.instance-details-error-container {
  position: absolute;
  bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  .notification-item {
    width: $notification-narrow-width;
  }
}