@import "../../variables";

.provider-login-layout {
  .radio-buttons-wrapper {
    margin-top: 30px;
    display: flex;
    user-select: none;
    &.disabled label {
      color: $grey-5;
    }
    .radio-option {
      input {
        margin: 0 8px 3px 0;
        vertical-align: middle;
      }
      &:first-of-type {
        margin-right: 40px;
      }
    }
  }
}