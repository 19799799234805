@import "./../../variables";
@import "./../../mixins";

@mixin step-number ($color: $primary-blue) {
  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-right: var(--margin-from-number);
    border-radius: 50%;
    border: 2px solid $color;
    width: 26px;
    height: 26px;
    color: $white;
    background-color: $color;
  }
}

.wizard-header-step {
  $svg-size: 27px;
  &.fade-in {
    animation: fade-in ease 0.5s forwards;
    opacity: 0;
  }
  &:not(:last-of-type) {
    margin-right: var(--margin-from-next-step);
  }

  position: relative;

  button {
    display: flex;
    align-items: center;
  }

  svg {
    width: $svg-size;
    margin-right: var(--margin-from-number);
  }

  .step-name {
    font-size: 14px;
    line-height: 1.8; //to prevent cutting of letters
    @include nowrap();
    max-width: 180px;
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: relative;
    svg {
      width: $svg-size;
    }
  }

  &.current {
    color: $accent-blue-1;
    @include step-number($accent-blue-1);
  }

  &.future {
    .number-text {
      //the number is on top of the circle svg
      position: absolute;
      left: 0;
      top: 0;
      width: $svg-size;
      height: $svg-size;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .faded-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    z-index: 1;
    &.right {
      background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
    &.left {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
}
