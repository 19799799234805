.relationshipCard {
    display: flex;
    flex-direction: row;
    height: 425px;

    .relationshipInfo {
        display: flex;
        flex-direction: column;
        width: 330px;
        background-color: var(--blue-70);
        color: var(--white);

        [class*=Typography-module_base] {
            color: var(--text-on-color);
        }
        
        .itemsContainer {
            padding: 41px 38px 0 38px;
            flex: 1;
        }

        .infoFooter {
            padding: 0 38px;
            height: 50px;
            
            .infoFooterDetails {
                display: flex;
                flex-direction: row;
                border-top: 1px solid var(--white);
                padding-top: 15px;

                .infoFooterDetailsText {
                    line-height: 14px;

                    &:first-child {
                        padding-right: 10px;
                        border-right: 1px solid var(--white);
                    }

                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}