@import "../../variables";
@import "../../mixins";

.instance-details-azure {
  .form-content {
    margin: 20px auto;
    width: 800px;
    display: flex;
    justify-content: space-between;
    .col {
      width: 360px;
    }
    label+.form-error {
      top: 75px;
    }

    .section-title {
      font-weight: 600;
      border-bottom: 1px solid $light-gray;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    .radio-options {
      float: left;
      &:first-of-type{
        margin-right: 22px;
      }
    }
    .notice {
        margin-right: 5px;
        color: $warning;
        font-weight: 600;
      }
    .checkbox {
        margin-bottom: 0;
        vertical-align: middle
    }
  }
}
