@import "../../variables";

.Suc-dialog .Suc-modal .Suc-default-dialog-layout.edit-credentials-dialog {
  max-width: 800px;
  .Suc-textarea {
    textarea {
      margin-bottom: 0;
    }
    margin-bottom: 30px;
  }
}
