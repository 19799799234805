@import 'variables';
@import 'mixins';
@import "~foundation-sites/scss/foundation";
@include foundation-everything($flex: true);

@import "components/layout";
@import "components/globals";
@import "components/newWizard/wizard";

@import "components/table/table";

@import "components/widgets/scrollbar";

@include foundation-flex-grid;

.Popover{
  z-index: 9999;
}

html, body {
  height: 100%;
}

body {
  display: block !important;
  overflow-x: hidden;
}

#noIE{
  display: none;
  &.show{
    display: block;
  }
}

#root.hide{
  display: none;
}
