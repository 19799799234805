@import "./../../variables";
@import "./../../mixins";

.data-broker-row {
  display: flex;
  .data-broker-summary {
    @include nowrap();
    display: flex;
    align-items: center;
    .data-broker-name {
      @include nowrap();
      margin-left: 10px;
    }
  }
  .data-broker-transfer-rate {
    margin-left: 10px;
  }
}

