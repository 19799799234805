@import "../../../variables";

$input-width: 213px;

.date-picker {
  position: relative;
  display: inline-block;

  &:hover{
    input:not([disabled]) {
      border-color: $primary-blue;
    }
    .calendar-icon:not([disabled]) {
      cursor: pointer;
      border-color: $primary-blue;
      svg {
        fill: $primary-blue;
      }
    }
  }
  &.active input {
    border-color: $primary-blue;
  }

  .react-datepicker {
    font-family: inherit;
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      height: 35px;
      input {
        cursor: pointer;
        width: $input-width;
        margin-bottom: 0;
        height: 35px;
        padding: 0 16px;
        &[disabled] {
          color: #d7d7d7 //the date should not be visible at all so it is same as background
        }
      }
      .react-datepicker__close-icon {
        &:after {
          color: #d7d7d7;
          background-color: transparent;
          font-size: 24px;
          font-weight: 200;
        }
        & ~ input {
          border-color: $primary-blue;
        }
      }
    }
  }

  .react-datepicker-popper {
    position: relative !important; //to override the library's inline styling
    transform: inherit !important; //to override the library's inline styling
    &[data-placement^="bottom"] {
      margin-top: 0;
    }
    .react-datepicker {
      border-color: $primary-blue;
      border-top: none;
      border-radius: 0;
    }
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;
      .react-datepicker__current-month {
        margin-bottom: 10px;
      }
    }
    .react-datepicker__month {
      margin: 0 20px 10px 20px;
    }
    .react-datepicker__day {
      width: 1.5rem;
    }
    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__day-names {
      font-size: 12px;
      color: #636363;
    }
    .react-datepicker__day--selected, .rightreact-datepicker__day--keyboard-selected {
      background-color: $primary-blue;
      border-radius: 0;
    }
    .react-datepicker__navigation--next {
      right: 40px;
    }
  }

  .year-navigation-overlay {
    cursor: pointer;
    z-index: 100;
    .nav-button {
      position: absolute;
      top: 14px;
      svg {
        width: 17px;
      }
      &:hover .st1{
        fill: $primary-blue;
      }
      &.left {
        left: 15px;
      }
      &.right {
        left: 215px;
      }
      &.disabled {
        cursor: default;
        .st1 {
          fill: #d7d7d7;
        }
      }
    }
  }

  .calendar-custom-header {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #636363;
    font-weight: 600;
    .date {
      position: relative;
      top: -2px;
    }
    button svg {
      width: 17px;
    }
    button:hover {
      .st1 {
        fill:$primary-blue;
      }
    }
    button[disabled] {
      cursor: default;
      svg .st1 {
        fill:#B7B7B7;
      }
    }
  }

  .calendar-icon {
    position: absolute;
    left: $input-width;
    padding: 5px;
    top: 0;
    height: 35px;
    border: 1px solid #d7d7d7;
    border-left: none;
    svg {
      width: 23px;
      height: 23px;
      fill: #d7d7d7;
    }
    &.active {
      border-color: $primary-blue;
      svg {
        fill: $primary-blue;
      }
    }
  }
}