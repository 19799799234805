@import "../../variables";
@import "../../mixins";

.instance-details-gcp {
  .form-content {
    width: 800px;
    display: flex;
    justify-content: space-between;

    .col {
      width: 360px;

      .permissions-label {
        font-size: 12px;
      }
    }
  }
}

