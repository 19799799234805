@import "../../variables";

@mixin sliderFromRight($slider-width: 489px, $top: 0){
  .slide-from-right-parent {
    position: fixed;
    right: 0;
    top: $top;
    box-shadow: 2px -2px 6px 2px rgba(0, 0, 0, 0.1);
    background-color: $white;
    z-index: 600;
    width: 0;

    &.slide-from-right-enter {
      width: 0;
    }

    &.slide-from-right-enter-active {
      width: $slider-width;
      transition: width 400ms ease-in !important;
    }

    &.slide-from-right-enter-done {
      width: $slider-width;
    }

    &.slide-from-right-exit {
      width: $slider-width;
    }

    &.slide-from-right-exit-active {
      width: 0;
      transition: width 400ms ease-in !important;
    }

    .slide-from-right-component {
      position: fixed;
      top: $top;
      bottom: 0;
    }

  }
  .slider-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    background: black;
    z-index: 500;
    &.show-mask {
      visibility: visible;
      opacity: 0.5;
    }
  }
}