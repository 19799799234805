@import "./../../widgets/card";
@import "./../../../variables";
@import "./../../../mixins";

$card-height: 100%;
$card-height-collapsed: 199px;
$card-width: 1217px;

.Suc-menu-popover.menu-popover.group-card {
  .Suc-tooltip-body {
    ul li {
      height: 40px;
    }
  }
}
.Suc-tooltip-container.Suc-tooltip-info {
  max-width: fit-content !important;
}

#root .manage-groups {
  .group-card {
    @include card(true);
    width: $card-width;
    height: 100%;
    box-shadow: $box-shadow-default;
    background-color: $white;

    margin: 0 0 8px 0;
    border: none;
    &.in-wizard {
      .collapse-button {
        right: 43px;
      }
    }
    &.menuable .card-menu .Suc-popover-trigger-container.Suc-menu-trigger {
      top: 39px;
      right: 42px;
      width: 25px;
      height: 25px;
      svg.Suc-circle-trigger {
        width: 25px;
        height: 25px;
      }
    }

    .card-body {
      padding: 20px 41px 31px 38px;
    }

    .double-line .broker-status-display .text {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.38;
      color: $tooltip-text-color;
    }

    .cards-container-block {
      position: relative;
    }

    &.collapsed {
      height: $card-height-collapsed;
    }
    .group-card-collapsed {
      display: flex;
      flex-direction: column;
      .group-card-header {
        display: flex;
        flex-direction: row;
        border-bottom:  solid 1px #979797;
        .Suc-button-base  {
          &:hover {
            cursor: pointer;
          }
          svg.orange-exclamation-icon {
            position: relative;
            left: -10px;
            top: -15px;
          }

        }
        .group-icon {
          width: 55px;
          svg {
            width: 55px;
            height: 55px;
          }
        }
        .group-name-container {
          width: 935px;
          height: 22px;
          display: flex;
          justify-content: flex-start;
          margin: 12px 11px 31px 21px;
          .group-name {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.38;
            color: $tooltip-text-color;
            max-width: 200px;
            @include nowrap();
          }
          svg {
            margin-left: 8px;
            position: relative;
            top: 3px;
          }
        }
      }
      .group-card-info {
        display: flex;
        flex-direction: row;
        padding-top: 31px;
        .double-line {
          .data-brokers-status-line {
            display: flex;
            justify-content: flex-start;
            .broker-status-display {
              margin-right: 16px;
              .icon {
                margin-right: 8px;
                svg {
                  width: 22px;
                  height: 22px;
                }
                .circled {
                  @include circledBorder(22px, $error-color);
                }
              }
            }

          }
          &.group-name {
            width: 256px;
          }
        }
      }
    }
  }
}

