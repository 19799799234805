@import "../../variables";

.in-table{
  padding-top: 3px;
  .tags-title {
    color: $dark-gray;
  }
  @media screen and ( max-width: 1400px ) {
    .tags-title.no-title {
      display: none;
    }
  }
}
.in-card-with-tags{
  margin-top: -5px;
}

.view-tags{
  svg{
    position: relative;
    top: 5px;
    left: 8px;
    width: 14px;
  }
}
.tags-in-table{
  line-height: 0;
  svg{
    width: 20px !important;
    height: 15px;
  }
}
.tags{
  float: right;
  //margin-left: -78px;
  //width: 142px;
  svg {
    width: 16px;
  }
}

tr {
  .view-tags{
    svg{
      //display: none;
      position: relative;
      margin-right: 17px;
      top: 4px;
    }
  }

  &:hover{
    &:not(.selected){
      .view-tags{
        svg{
          fill: $dark-gray;
          position: relative;
        }
      }
    }

    .view-tags{

      &:hover{
        color: $dark-blue;
        svg{
          fill: $dark-blue;
          position: relative;
        }
      }

      svg{
        display: inline;
      }
    }
  }

  .active>.view-tags{
    color: $dark-blue;
    svg{
      fill: $dark-blue;
      display: inline;
      position: relative;
    }
  }
}

.Suc-tooltip-container.tags-table {
  width: 200px;
  .Suc-tooltip-body {
    width: 651px;
    padding: 20px 30px;
    background-color: $tooltip-background-color;
    color: $tooltip-text-color;
  }

  .popover-table-header {
    font-size: 15px;
    font-weight: bolder;
    display: flex;
    gap: 60px;
    padding: 12px 0 0 30px ;
  }

  .scrollarea.popover-table {
    margin-top: 20px;
    padding-left: 6px;
    .scrollbar-container.vertical {
      &:before {
        content: "";
        height: 100%;
        position: absolute;
        width: 1px;
        display: block;
        border-right: 1px solid #8a9eb3;
        margin-left: 2px;
      }
    }
  }

  .popover-table-content {
    .table-row {
      font-size: 13px;
      height: 40px;
      line-height: 40px;

      &:not(:last-child) {
        border-bottom: 1px solid #738ba3;
      }


      > div {
        width: 50%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
      }
    }
  }
}

